import { toString as toString_1, Union } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { class_type, union_type, int32_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { toISO8601 } from "./Utils.js";
import { today, date, compare, toString, addYears, addMonths, now, addDays } from "./fable_modules/fable-library-js.4.17.0/Date.js";

export class PeriodSpan extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Days", "Weeks", "Months", "Quarters", "Years"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? (`${this$.fields[0]} uker`) : ((this$.tag === 2) ? (`${this$.fields[0]} måneder`) : ((this$.tag === 3) ? (`${this$.fields[0]} kvartal`) : ((this$.tag === 4) ? (`${this$.fields[0]} år`) : (`${this$.fields[0]} dager`))));
    }
}

export function PeriodSpan_$reflection() {
    return union_type("Investfora.Helpers.PeriodSpan", [], PeriodSpan, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export function PeriodSpan__ToValue(this$) {
    switch (this$.tag) {
        case 1:
            return "weeks";
        case 2:
            return "months";
        case 3:
            return "quarters";
        case 4:
            return "years";
        default:
            return "days";
    }
}

export function PeriodSpan_FromValue(x, y) {
    switch (x) {
        case "days":
            return new PeriodSpan(0, [y]);
        case "weeks":
            return new PeriodSpan(1, [y]);
        case "months":
            return new PeriodSpan(2, [y]);
        case "quarters":
            return new PeriodSpan(3, [y]);
        case "years":
            return new PeriodSpan(4, [y]);
        default:
            return undefined;
    }
}

export function PeriodSpan__ToDraft(this$) {
    const patternInput = (this$.tag === 1) ? ["weeks", this$.fields[0]] : ((this$.tag === 2) ? ["months", this$.fields[0]] : ((this$.tag === 3) ? ["quarters", this$.fields[0]] : ((this$.tag === 4) ? ["years", this$.fields[0]] : ["days", this$.fields[0]])));
    return {
        Num: patternInput[1],
        Value: patternInput[0],
    };
}

export function PeriodSpan__ToDescription(this$) {
    switch (this$.tag) {
        case 1:
            return `for en periode på ${this$.fields[0]} uker`;
        case 2:
            return `for en periode på ${this$.fields[0]} måneder`;
        case 3:
            return `for en periode på ${this$.fields[0]} kvartal`;
        case 4:
            return `for en periode på ${this$.fields[0]} år`;
        default:
            return `for en periode på ${this$.fields[0]} dager`;
    }
}

export function PeriodSpan__ToNumber(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0] | 0;
        case 2:
            return this$.fields[0] | 0;
        case 3:
            return this$.fields[0] | 0;
        case 4:
            return this$.fields[0] | 0;
        default:
            return this$.fields[0] | 0;
    }
}

export function PeriodSpan__Validate(this$) {
    switch (this$.tag) {
        case 1: {
            const x_1 = this$.fields[0] | 0;
            if (x_1 >= 1) {
                return x_1 <= 100;
            }
            else {
                return false;
            }
        }
        case 2: {
            const x_2 = this$.fields[0] | 0;
            if (x_2 >= 1) {
                return x_2 <= 100;
            }
            else {
                return false;
            }
        }
        case 3: {
            const x_3 = this$.fields[0] | 0;
            if (x_3 >= 1) {
                return x_3 <= 40;
            }
            else {
                return false;
            }
        }
        case 4: {
            const x_4 = this$.fields[0] | 0;
            if (x_4 >= 1) {
                return x_4 <= 20;
            }
            else {
                return false;
            }
        }
        default: {
            const x = this$.fields[0] | 0;
            if (x >= 1) {
                return x <= 1000;
            }
            else {
                return false;
            }
        }
    }
}

export function PeriodSpan__ToDateString(this$) {
    switch (this$.tag) {
        case 1:
            return toISO8601(addDays(now(), this$.fields[0] * 7));
        case 2:
            return toISO8601(addMonths(now(), this$.fields[0]));
        case 3:
            return toISO8601(addMonths(now(), this$.fields[0] * 3));
        case 4:
            return toISO8601(addYears(now(), this$.fields[0]));
        default:
            return toISO8601(addDays(now(), this$.fields[0]));
    }
}

export class PeriodType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Span", "ExactDate", "AllTime"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? (`til ${toString(this$.fields[0], "dd/MM/yy")}`) : ((this$.tag === 2) ? "All tid" : toString_1(this$.fields[0]));
    }
}

export function PeriodType_$reflection() {
    return union_type("Investfora.Helpers.PeriodType", [], PeriodType, () => [[["Item", PeriodSpan_$reflection()]], [["Item", class_type("System.DateTime")]], []]);
}

export function PeriodType__ToDescription(this$) {
    switch (this$.tag) {
        case 0:
            return PeriodSpan__ToDescription(this$.fields[0]);
        case 1:
            return `til ${toString(this$.fields[0], "dd/MM/yy")}`;
        default:
            return "for alltid";
    }
}

export function PeriodType__ToValue(this$) {
    switch (this$.tag) {
        case 1:
            return "date";
        case 2:
            return "alltime";
        default:
            return "span";
    }
}

export function PeriodType__UpdateSpanLength_Z524259A4(this$, x) {
    if (this$.tag === 0) {
        const y = this$.fields[0];
        return new PeriodType(0, [(y.tag === 1) ? (new PeriodSpan(1, [x])) : ((y.tag === 2) ? (new PeriodSpan(2, [x])) : ((y.tag === 3) ? (new PeriodSpan(3, [x])) : ((y.tag === 4) ? (new PeriodSpan(4, [x])) : (new PeriodSpan(0, [x])))))]);
    }
    else {
        return this$;
    }
}

export function PeriodType__IncrementSpanLength(this$) {
    if (this$.tag === 0) {
        const y = this$.fields[0];
        return new PeriodType(0, [(y.tag === 1) ? (new PeriodSpan(1, [y.fields[0] + 1])) : ((y.tag === 2) ? (new PeriodSpan(2, [y.fields[0] + 1])) : ((y.tag === 3) ? (new PeriodSpan(3, [y.fields[0] + 1])) : ((y.tag === 4) ? (new PeriodSpan(4, [y.fields[0] + 1])) : (new PeriodSpan(0, [y.fields[0] + 1])))))]);
    }
    else {
        return this$;
    }
}

export function PeriodType__DecrementSpanLength(this$) {
    let x_1, x_2, x_3, x_4, x;
    if (this$.tag === 0) {
        const y = this$.fields[0];
        return new PeriodType(0, [(y.tag === 1) ? ((x_1 = (y.fields[0] | 0), (x_1 > 1) ? (new PeriodSpan(1, [x_1 - 1])) : (new PeriodSpan(1, [x_1])))) : ((y.tag === 2) ? ((x_2 = (y.fields[0] | 0), (x_2 > 1) ? (new PeriodSpan(2, [x_2 - 1])) : (new PeriodSpan(2, [x_2])))) : ((y.tag === 3) ? ((x_3 = (y.fields[0] | 0), (x_3 > 1) ? (new PeriodSpan(3, [x_3 - 1])) : (new PeriodSpan(3, [x_3])))) : ((y.tag === 4) ? ((x_4 = (y.fields[0] | 0), (x_4 > 1) ? (new PeriodSpan(4, [x_4 - 1])) : (new PeriodSpan(4, [x_4])))) : ((x = (y.fields[0] | 0), (x > 1) ? (new PeriodSpan(0, [x - 1])) : (new PeriodSpan(0, [x])))))))]);
    }
    else {
        return this$;
    }
}

export function PeriodType__SetExactDate_7F9DDECF(this$, x) {
    if (this$.tag === 1) {
        if (compare(date(x), today()) > 0) {
            return new PeriodType(1, [x]);
        }
        else {
            return this$;
        }
    }
    else {
        return this$;
    }
}

export function PeriodType__Morph_Z721C83C5(this$, x) {
    switch (x) {
        case "days":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(0, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(0, [1])]);
            }
        case "weeks":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(1, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(1, [1])]);
            }
        case "months":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(2, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(2, [1])]);
            }
        case "quarters":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(3, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(3, [1])]);
            }
        case "years":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(4, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(4, [1])]);
            }
        case "exact":
            if (this$.tag === 1) {
                return this$;
            }
            else {
                return new PeriodType(1, [addDays(today(), 1)]);
            }
        default:
            return this$;
    }
}

export function PeriodType__Validate(this$) {
    switch (this$.tag) {
        case 0:
            return PeriodSpan__Validate(this$.fields[0]);
        case 1:
            return compare(date(this$.fields[0]), date(addDays(now(), 1))) >= 0;
        default:
            return true;
    }
}

export function PeriodType__ToPeriodEnd(this$) {
    switch (this$.tag) {
        case 0:
            return PeriodSpan__ToDateString(this$.fields[0]);
        case 1:
            return toISO8601(this$.fields[0]);
        default:
            return "alltime";
    }
}

