import { Record, Union } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, record_type, array_type, option_type, string_type, union_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { Analytics_Average_$reflection, Asset_$reflection, Market_$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { equals, createObj, comparePrimitives, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { DataServerBaseUrl } from "./Session.js";
import { map as map_1, sortByDescending, sortBy } from "./fable_modules/fable-library-js.4.17.0/Array.js";
import { createElement } from "react";
import React from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { append, empty as empty_1, singleton, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { LoadingIndicator_skeleton, Alert_snackError } from "./ViewHelpers.js";
import { Dimensions_TextSmall } from "./Design.js";

class Sort extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NameDesc", "NameAsc", "TickerDesc", "TickerAsc"];
    }
}

function Sort_$reflection() {
    return union_type("Investfora.Market.Sort", [], Sort, () => [[], [], [], []]);
}

class Model extends Record {
    constructor(SortOrder, MarketTicker, MarketInfo, Assets, AvgRating, ErrorMsg) {
        super();
        this.SortOrder = SortOrder;
        this.MarketTicker = MarketTicker;
        this.MarketInfo = MarketInfo;
        this.Assets = Assets;
        this.AvgRating = AvgRating;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.Market.Model", [], Model, () => [["SortOrder", Sort_$reflection()], ["MarketTicker", string_type], ["MarketInfo", option_type(Market_$reflection())], ["Assets", option_type(array_type(Asset_$reflection()))], ["AvgRating", option_type(Analytics_Average_$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MarketResponse", "AssetsResponse", "AverageResponse", "SetSortOrder", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.Market.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Market_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Market_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Asset_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Asset_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Analytics_Average_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Analytics_Average_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", Sort_$reflection()]], []]);
}

function init(ticker) {
    return new Model(new Sort(1, []), ticker, undefined, undefined, undefined, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.SortOrder, model.MarketTicker, model.MarketInfo, model.Assets, model.AvgRating, "Could not load assets for this market.");
            }
            else {
                return new Model(model.SortOrder, model.MarketTicker, model.MarketInfo, res_1.fields[0], model.AvgRating, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.SortOrder, model.MarketTicker, model.MarketInfo, model.Assets, res_2.fields[0], model.ErrorMsg);
            }
        }
        case 3:
            return new Model(msg.fields[0], model.MarketTicker, model.MarketInfo, model.Assets, model.AvgRating, model.ErrorMsg);
        case 4:
            return new Model(model.SortOrder, model.MarketTicker, model.MarketInfo, model.Assets, model.AvgRating, undefined);
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.SortOrder, model.MarketTicker, model.MarketInfo, model.Assets, model.AvgRating, "Could not load market info.");
            }
            else {
                return new Model(model.SortOrder, model.MarketTicker, res.fields[0], model.Assets, model.AvgRating, model.ErrorMsg);
            }
        }
    }
}

function fetchMarketInfo(ticker, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(Market_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/market/ticker/${ticker}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Market_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchAssets(ticker, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Asset_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/market/assets/${ticker}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Asset_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(1, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchAverage(ticker, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(Analytics_Average_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/market/average?ticker=${ticker}&period=active`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Analytics_Average_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(2, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function listAssets(model, assets, dispatch) {
    let children_4, children, elems_9, elems_3, elems_2, elems_1, elems_6, elems_5, elems_4, elems_8, children_2;
    let x_5;
    const x = assets;
    const matchValue = model.SortOrder;
    x_5 = ((matchValue.tag === 1) ? sortBy((y_2) => y_2.Name, x, {
        Compare: comparePrimitives,
    }) : ((matchValue.tag === 2) ? sortByDescending((y_4) => y_4.Ticker, x, {
        Compare: comparePrimitives,
    }) : ((matchValue.tag === 3) ? sortBy((y_6) => y_6.Ticker, x, {
        Compare: comparePrimitives,
    }) : sortByDescending((y) => y.Name.toLocaleLowerCase(), x, {
        Compare: comparePrimitives,
    }))));
    const td$0027 = (txt, link) => {
        let elems;
        return createElement("td", createObj(ofArray([["style", {
            padding: 16,
        }], (elems = [createElement("a", {
            href: link,
            children: txt,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    };
    return createElement("div", {
        style: {
            width: 100 + "%",
            maxWidth: 800,
        },
        children: (children_4 = ofArray([(children = singleton_1(createElement("tr", createObj(ofArray([["style", {
            backgroundColor: "var(--bg-normal)",
        }], (elems_9 = [createElement("th", createObj(ofArray([["style", {
            color: "#f6f6f6",
            cursor: "pointer",
        }], ["onClick", (_arg) => {
            if (equals(model.SortOrder, new Sort(3, []))) {
                dispatch(new Message(3, [new Sort(2, [])]));
            }
            else {
                dispatch(new Message(3, [new Sort(3, [])]));
            }
        }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
            display: "flex",
        }], (elems_2 = ["Ticker", createElement("div", createObj(ofArray([["style", {
            width: 10,
            marginLeft: 5,
        }], (elems_1 = toList(delay(() => (equals(model.SortOrder, new Sort(3, [])) ? singleton(createElement("span", {
            className: "fas fa-caret-down",
        })) : (equals(model.SortOrder, new Sort(2, [])) ? singleton(createElement("span", {
            className: "fas fa-caret-up",
        })) : empty_1())))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("th", createObj(ofArray([["style", {
            color: "#f6f6f6",
            cursor: "pointer",
        }], ["onClick", (_arg_1) => {
            if (equals(model.SortOrder, new Sort(1, []))) {
                dispatch(new Message(3, [new Sort(0, [])]));
            }
            else {
                dispatch(new Message(3, [new Sort(1, [])]));
            }
        }], (elems_6 = [createElement("div", createObj(ofArray([["style", {
            display: "flex",
        }], (elems_5 = ["Navn", createElement("div", createObj(ofArray([["style", {
            width: 10,
            marginLeft: 5,
        }], (elems_4 = toList(delay(() => (equals(model.SortOrder, new Sort(1, [])) ? singleton(createElement("span", {
            className: "fas fa-caret-down",
        })) : (equals(model.SortOrder, new Sort(0, [])) ? singleton(createElement("span", {
            className: "fas fa-caret-up",
        })) : empty_1())))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("th", createObj(ofArray([["style", {
            color: "#f6f6f6",
        }], (elems_8 = [createElement("div", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray(["ISIN"]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), (children_2 = map_1((x_6) => {
            let elems_10;
            const link_1 = (x_6.Type === 1) ? (`/stock/${x_6.Market.Ticker}/${x_6.Ticker}`) : (`/crypto/${x_6.Ticker}`);
            return createElement("tr", createObj(ofArray([["style", {
                backgroundColor: "#fff",
            }], (elems_10 = [td$0027(x_6.Ticker, link_1), td$0027(x_6.Name, link_1), td$0027(x_6.ISIN, link_1)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
        }, x_5), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))]), createElement("table", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        })),
    });
}

export function Market(marketInputProps) {
    let elems_1;
    const ticker = marketInputProps.ticker;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(ticker));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchMarketInfo(ticker, dispatch);
        fetchAssets(ticker, dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
    }], (elems_1 = toList(delay(() => append(singleton(Alert_snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(4, []));
    })), delay(() => {
        let matchValue;
        return append(singleton(createElement("h1", {
            className: "title is-1",
            style: {
                textAlign: "center",
            },
            children: (matchValue = model_1.MarketInfo, (matchValue != null) ? matchValue.Name : model_1.MarketTicker),
        })), delay(() => append(singleton(createElement("p", {
            style: {
                fontSize: Dimensions_TextSmall,
            },
            children: "Du kan også lete etter aksjer som ikke lengre er notert gjennom søkefunksjonen.",
        })), delay(() => {
            let elems;
            const matchValue_1 = model_1.Assets;
            if (matchValue_1 == null) {
                const loadingBar = () => LoadingIndicator_skeleton(ofArray([["width", 100 + "%"], ["marginTop", 10], ["minHeight", 50]]));
                return singleton(createElement("div", createObj(ofArray([["style", {
                    width: 100 + "%",
                    maxWidth: 800,
                }], (elems = [loadingBar(), loadingBar(), loadingBar(), loadingBar()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
            }
            else {
                return singleton(listAssets(model_1, matchValue_1, dispatch));
            }
        }))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

