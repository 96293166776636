import { toUniversalTime, toString, now, op_Subtraction, parse } from "./fable_modules/fable-library-js.4.17.0/Date.js";
import { totalDays, totalMinutes } from "./fable_modules/fable-library-js.4.17.0/TimeSpan.js";
import { format, toText } from "./fable_modules/fable-library-js.4.17.0/String.js";
import { toFloat64, op_Multiply, toInt64, compare } from "./fable_modules/fable-library-js.4.17.0/BigInt.js";

export function utcStringToLocalTimeSpan(dt) {
    let x_2, x_3, x_4, x_5;
    try {
        const date = parse(dt);
        const matchValue = ~~totalMinutes(op_Subtraction(now(), date)) | 0;
        return (matchValue < 1) ? "Akkurat nå" : ((matchValue < 60) ? (`${matchValue} minutter siden`) : (((x_2 = (matchValue | 0), (x_2 >= 60) && (x_2 < 120))) ? "~1 time siden" : (((x_3 = (matchValue | 0), (x_3 >= 120) && (x_3 < 1440))) ? (`~${~~(matchValue / 60)} timer siden`) : (((x_4 = (matchValue | 0), (x_4 >= 1440) && (x_4 < 2880))) ? "~1 dag siden" : (((x_5 = (matchValue | 0), (x_5 >= 2880) && (x_5 < 4320))) ? (`~${~~(matchValue / (60 * 24))} dager siden`) : toString(date, "dd/MM/yyyy"))))));
    }
    catch (matchValue_1) {
        return "";
    }
}

export function utcStringToLocalDaySpan(dt) {
    try {
        const date = parse(dt);
        const matchValue = totalDays(op_Subtraction(now(), date));
        return (matchValue < 1) ? "I dag" : ((matchValue < 2) ? "I går" : ((matchValue < 5) ? (`${~~matchValue} dager siden`) : toString(date, "dd/MM/yyyy")));
    }
    catch (matchValue_1) {
        return "";
    }
}

export function utcStringToTimePeriod(dt) {
    try {
        const days = totalDays(op_Subtraction(parse(dt), now()));
        return (days < -1) ? "" : ((days < 0) ? "~ i dag" : ((days < 2) ? "~ i morgen" : ((days < 7) ? toText(`~${~~days} fra nå`) : ((days < 14) ? "~1 uke fra nå" : ((days < 31) ? (`~${~~(days / 7)} uker fra nå`) : ((days < 45) ? "~1/2 kvartal fra nå" : ((days < 61) ? "~2 måneder fra nå" : ((days < 135) ? "~1 kvartal fra nå" : ((days < 160) ? "~5 måneder fra nå" : ((days < 200) ? "~1/2 år fra nå" : ((days < 240) ? "~7 måneder fra nå" : ((days < 300) ? "~3 kvartal fra nå" : ((days < 400) ? "~1 år fra nå" : (`~${~~(days / 30)} fra nå`))))))))))))));
    }
    catch (matchValue) {
        return "";
    }
}

export function utcDateToLocalDate(x) {
    try {
        return toString(parse(x), "dd/MM/yyyy");
    }
    catch (matchValue) {
        return x;
    }
}

export function utcDateToLocalDayAndMonth(x) {
    try {
        return toString(parse(x), "dd/MM");
    }
    catch (matchValue) {
        return x;
    }
}

export function toISO8601(dt) {
    return toString(dt, "yyyy-MM-dd HH:mmZ");
}

export function parseAsUtc(dt) {
    return toUniversalTime(parse(dt));
}

export function toShortAmount(x) {
    const x$0027 = ((x < 0) ? (x * -1) : x) | 0;
    if (x$0027 >= 1000000000) {
        const bn = x / 1000000000;
        return `${format('{0:' + "0.0" + '}', bn)} mrd`;
    }
    else if (x$0027 >= 1000000) {
        const mill = x / 1000000;
        return `${format('{0:' + "0.0" + '}', mill)}m`;
    }
    else if (x$0027 >= 1000) {
        const k = x / 1000;
        return `${format('{0:' + "0.0" + '}', k)}k`;
    }
    else {
        return `${x}`;
    }
}

export function toShortAmountL(x$0027) {
    const x = (compare(x$0027, 0n) < 0) ? toInt64(op_Multiply(x$0027, -1n)) : x$0027;
    if (compare(x, 1000000000n) >= 0) {
        const bn = toFloat64(x$0027) / 1000000000;
        return `${format('{0:' + "0.00" + '}', bn)} mrd`;
    }
    else if (compare(x, 1000000n) >= 0) {
        const mill = toFloat64(x$0027) / 1000000;
        return `${format('{0:' + "0.00" + '}', mill)}m`;
    }
    else if (compare(x, 1000n) >= 0) {
        const k = toFloat64(x$0027) / 1000;
        return `${format('{0:' + "0.0" + '}', k)}k`;
    }
    else {
        return `${x$0027}`;
    }
}

export function toPercentageChange(old, last) {
    return ((100 * old) / last) - 100;
}

export function toPositivePercentageChange(old, last) {
    const change = ((100 * old) / last) - 100;
    if (change < 0) {
        return change * -1;
    }
    else {
        return change;
    }
}

/**
 * Converts a short percentage as whole hundreds to string
 */
export function shortPercentageToString(p) {
    const p_1 = p / 100;
    return format('{0:' + "0.00%" + '}', p_1);
}

/**
 * Converts a quote price to string representation
 */
export function quoteToString(x) {
    return format('{0:' + "0.00" + '}', x);
}

/**
 * Format large number values to space-seperated thousands presentation
 */
export function formatSpacedNumber(x) {
    return x.toLocaleString("fr", {
        minimumFractionDigits: "0",
        maximumFractionDigits: "2",
        useGrouping: true,
    });
}

/**
 * Format large number values to space-seperated thousands presentation
 */
export function formatSpacedLong(x) {
    return x.toLocaleString("fr", {
        minimumFractionDigits: "0",
        maximumFractionDigits: "2",
        useGrouping: true,
    });
}

export function updateInputField(fieldId, newVal) {
    try {
        const entry = window.document.getElementById(fieldId);
        entry.value = newVal;
    }
    catch (matchValue) {
    }
}

export function updateInputFieldWithOnChange(fieldId, newVal) {
    try {
        const entry = window.document.getElementById(fieldId);
        entry.value = newVal;
        const event = new EventTarget("change");
        entry.dispatchEvent(event);
    }
    catch (matchValue) {
    }
}

export function tryFocusInput(inputId) {
    try {
        window.document.getElementById(inputId).focus();
    }
    catch (matchValue) {
    }
}

export function checkError(err) {
    let matchResult, x_1;
    if (err.tag === 2) {
        if ((err.fields[0].status) === 401) {
            matchResult = 0;
            x_1 = err.fields[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            window.location.reload();
            break;
        }
        case 1: {
            break;
        }
    }
}

