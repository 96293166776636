import { Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, record_type, string_type, option_type, array_type, int32_type, union_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { DataServer_InsiderTrade_InsiderTradeAssetEntry_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { int32ToString, createObj, uncurry2 } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { sumBy } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { createElement } from "react";
import React from "react";
import { DefaultBorder, Dimensions_TextSmall } from "../Design.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { append, singleton, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Label } from "recharts";
import { StockRating, StockRating__ToColorCode } from "../Shared/Shared.js";
import { toShortAmountL, toShortAmount } from "../Utils.js";
import { op_Addition, fromInt32, op_Multiply, toInt64 } from "../fable_modules/fable-library-js.4.17.0/BigInt.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { LoadingIndicator_skeleton, Alert_snackError } from "../ViewHelpers.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";

class PeriodOption extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["TwoMonths", "OneMonth", "SevenDays"];
    }
}

function PeriodOption_$reflection() {
    return union_type("Investfora.AssetInsiderTrade.PeriodOption", [], PeriodOption, () => [[], [], []]);
}

function PeriodOption_FromId_Z524259A4(x) {
    switch (x) {
        case 2:
            return new PeriodOption(1, []);
        case 3:
            return new PeriodOption(2, []);
        default:
            return new PeriodOption(0, []);
    }
}

function PeriodOption__ToId(this$) {
    switch (this$.tag) {
        case 1:
            return 2;
        case 2:
            return 3;
        default:
            return 1;
    }
}

function PeriodOption__ToHttpVar(this$) {
    switch (this$.tag) {
        case 1:
            return "1m";
        case 2:
            return "7d";
        default:
            return "2m";
    }
}

class Model extends Record {
    constructor(AssetId, Summary, Period, ErrorMsg) {
        super();
        this.AssetId = (AssetId | 0);
        this.Summary = Summary;
        this.Period = Period;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.AssetInsiderTrade.Model", [], Model, () => [["AssetId", int32_type], ["Summary", option_type(array_type(DataServer_InsiderTrade_InsiderTradeAssetEntry_$reflection()))], ["Period", PeriodOption_$reflection()], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SummaryResponse", "UpdatePeriod", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.AssetInsiderTrade.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(DataServer_InsiderTrade_InsiderTradeAssetEntry_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(DataServer_InsiderTrade_InsiderTradeAssetEntry_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", PeriodOption_$reflection()]], []]);
}

function init(assetId) {
    return new Model(assetId, undefined, new PeriodOption(0, []), undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1:
            return new Model(model.AssetId, model.Summary, msg.fields[0], model.ErrorMsg);
        case 2:
            return new Model(model.AssetId, model.Summary, model.Period, undefined);
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.AssetId, model.Summary, model.Period, "Kunne ikke laste innsidehandel oppsummering. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.AssetId, res.fields[0], model.Period, model.ErrorMsg);
            }
        }
    }
}

function fetchSummary(assetId, po, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = `/api/asset/insider-trade/summary/${assetId}?period=${PeriodOption__ToHttpVar(po)}`;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_InsiderTrade_InsiderTradeAssetEntry_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_InsiderTrade_InsiderTradeAssetEntry_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

class PiePoint extends Record {
    constructor(Aksjer) {
        super();
        this.Aksjer = (Aksjer | 0);
    }
}

function PiePoint_$reflection() {
    return record_type("Investfora.AssetInsiderTrade.PiePoint", [], PiePoint, () => [["Aksjer", int32_type]]);
}

function drawSummary(model, dispatch, x) {
    let elems_13, elems_3, elems_12, elems_6, elems_7, elems_10, elems_11;
    const buys = x.filter((i) => (i.NumShares > 0));
    const sells = x.filter((i_1) => (i_1.NumShares < 0));
    const numBoughtShares = sumBy((b) => b.NumShares, buys, {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) | 0;
    const numSoldShares = (-1 * sumBy((b_1) => b_1.NumShares, sells, {
        GetZero: () => 0,
        Add: (x_2, y_1) => (x_2 + y_1),
    })) | 0;
    const numSharesData = [{
        name: "Antall kjøpt",
        value: numBoughtShares,
    }, {
        name: "Antall solgt",
        value: numSoldShares,
    }];
    const boughKs = sumBy((i_2) => i_2.EstValueK, buys, {
        GetZero: () => 0,
        Add: (x_4, y_3) => (x_4 + y_3),
    }) | 0;
    const soldKs = (-1 * sumBy((i_3) => i_3.EstValueK, sells, {
        GetZero: () => 0,
        Add: (x_5, y_4) => (x_5 + y_4),
    })) | 0;
    const valuationData = [{
        name: "Verdi kjøpt",
        value: boughKs,
    }, {
        name: "Verdi solgt",
        value: soldKs,
    }];
    const numText = (content, title) => {
        let elems;
        return createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }], (elems = [createElement("span", {
            style: {
                fontSize: 1.2 + "rem",
                fontWeight: "bold",
            },
            children: content,
        }), createElement("span", {
            style: {
                fontSize: Dimensions_TextSmall,
            },
            children: title,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    };
    const graphBox = (children) => createElement("div", {
        style: createObj(ofArray([["display", "flex"], ["width", 100 + "%"], ["maxWidth", 400], ["backgroundColor", "#f8f8f8"], DefaultBorder, ["borderRadius", 5], ["padding", 5]])),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
    const notEnoughData = () => {
        let elems_2;
        return createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            height: "inherit",
            justifyContent: "center",
        }], (elems_2 = [createElement("span", {
            style: {
                fontStyle: "italic",
                fontSize: Dimensions_TextSmall,
            },
            children: "Ikke nok data for valgt periode",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    };
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        gap: ((10 + "px ") + 10) + "px",
    }], (elems_13 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        gap: ((10 + "px ") + 10) + "px",
        width: 100 + "%",
        justifyContent: "center",
        borderBottom: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        paddingBottom: 10,
    }], (elems_3 = [numText(int32ToString(x.length), "Handler"), numText(int32ToString(buys.length), "Innsidekjøp"), numText(int32ToString(sells.length), "Innsidesalg")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        gap: ((10 + "px ") + 10) + "px",
        borderBottom: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        paddingBottom: 10,
    }], (elems_12 = [graphBox([createElement("div", createObj(ofArray([["style", {
        height: 200,
        width: 200,
    }], (elems_6 = toList(delay(() => {
        let properties_23, properties_22, elements_1, properties_21, elements, properties_19, properties_20;
        return (x.length === 0) ? singleton(notEnoughData()) : singleton((properties_23 = ofArray([["minWidth", 250], ["minHeight", 210], ["children", (properties_22 = ofArray([["margin", {
            top: defaultArg(5, 0),
            right: defaultArg(0, 0),
            left: defaultArg(0, 0),
            bottom: defaultArg(20, 0),
        }], (elements_1 = ofArray([Interop_reactApi_1.createElement(Label, {}), Interop_reactApi_1.createElement(Legend, {}), (properties_21 = ofArray([["data", numSharesData], ["dataKey", "value"], ["innerRadius", 40 + "%"], (elements = ofArray([(properties_19 = ofArray([["key", "Antall kjøpt"], ["fill", StockRating__ToColorCode(new StockRating(0, []))]]), Interop_reactApi_1.createElement(Cell, createObj(properties_19))), (properties_20 = ofArray([["key", "Antall solgt"], ["fill", StockRating__ToColorCode(new StockRating(4, []))]]), Interop_reactApi_1.createElement(Cell, createObj(properties_20)))]), ["children", ["children", Interop_reactApi.Children.toArray(Array.from(elements))]])]), Interop_reactApi_1.createElement(Pie, createObj(properties_21)))]), ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), Interop_reactApi_1.createElement(PieChart, createObj(properties_22)))]]), Interop_reactApi_1.createElement(ResponsiveContainer, createObj(properties_23))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        gap: ((10 + "px ") + 10) + "px",
        width: 100 + "%",
        maxWidth: 170,
        justifyContent: "center",
    }], (elems_7 = [numText(toShortAmount(numBoughtShares), "Antall aksjer kjøpt"), numText(toShortAmount(numSoldShares), "Antall aksjer solgt"), numText(toShortAmount(numBoughtShares + numSoldShares), "Antall aksjer")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))]), graphBox([createElement("div", createObj(ofArray([["style", {
        height: 200,
        width: 200,
    }], (elems_10 = toList(delay(() => {
        let properties_34, properties_33, elements_3, properties_32, elements_2, properties_30, properties_31;
        return (x.length === 0) ? singleton(notEnoughData()) : singleton((properties_34 = ofArray([["minWidth", 250], ["minHeight", 210], ["children", (properties_33 = ofArray([["margin", {
            top: defaultArg(5, 0),
            right: defaultArg(0, 0),
            left: defaultArg(0, 0),
            bottom: defaultArg(20, 0),
        }], (elements_3 = ofArray([Interop_reactApi_1.createElement(Label, {}), Interop_reactApi_1.createElement(Legend, {}), (properties_32 = ofArray([["dataKey", "value"], ["innerRadius", 40 + "%"], ["data", valuationData], (elements_2 = ofArray([(properties_30 = ofArray([["key", "Verdi kjøpt"], ["fill", StockRating__ToColorCode(new StockRating(0, []))]]), Interop_reactApi_1.createElement(Cell, createObj(properties_30))), (properties_31 = ofArray([["key", "Verdi solgt"], ["fill", StockRating__ToColorCode(new StockRating(4, []))]]), Interop_reactApi_1.createElement(Cell, createObj(properties_31)))]), ["children", ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))]])]), Interop_reactApi_1.createElement(Pie, createObj(properties_32)))]), ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]), Interop_reactApi_1.createElement(PieChart, createObj(properties_33)))]]), Interop_reactApi_1.createElement(ResponsiveContainer, createObj(properties_34))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        gap: ((10 + "px ") + 10) + "px",
        width: 100 + "%",
        maxWidth: 170,
        justifyContent: "center",
    }], (elems_11 = [numText(toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(boughKs)), 1000n))), "Est. verdi kjøp"), numText(toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(soldKs)), 1000n))), "Est. verdi solgt"), numText(toShortAmountL(toInt64(op_Addition(toInt64(op_Multiply(toInt64(fromInt32(boughKs)), 1000n)), toInt64(op_Multiply(toInt64(fromInt32(soldKs)), 1000n))))), "Est. samlet handelsverdi")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])));
}

export function AssetInsiderTrade(assetInsiderTradeInputProps) {
    let elems_7;
    const assetId = assetInsiderTradeInputProps.assetId;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(assetId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchSummary(model_1.AssetId, model_1.Period, dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        padding: 2 + "vw",
    }], (elems_7 = toList(delay(() => append(singleton(Alert_snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(2, []));
    })), delay(() => {
        let elems_4, elems, elems_3, elements, elems_1;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: 20,
        }], (elems_4 = [createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
        }], (elems = [createElement("h2", {
            style: {
                fontSize: 2 + "rem",
                fontWeight: 500,
                margin: 0,
            },
            children: "Innsidehandler",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
            display: "flex",
            justifyContent: "flex-end",
        }], (elems_3 = [MuiHelpers_createElement(FormControl, [(elements = [MuiHelpers_createElement(InputLabel, [["variant", "standard"], ["id", "period-select-label-id"], ["children", "Periode"]]), MuiHelpers_createElement(Select, [["id", "period-select-id"], ["labelId", "period-select-label-id"], ["label", "Periode"], ["variant", "standard"], ["value", PeriodOption__ToId(model_1.Period)], ["onChange", (e, _arg_2) => {
            const p = PeriodOption_FromId_Z524259A4(e.target.value);
            dispatch(new Message(1, [p]));
            fetchSummary(model_1.AssetId, p, dispatch);
        }], (elems_1 = [MuiHelpers_createElement(MenuItem, [["value", PeriodOption__ToId(new PeriodOption(0, []))], ["children", "2 måneder"]]), MuiHelpers_createElement(MenuItem, [["value", PeriodOption__ToId(new PeriodOption(1, []))], ["children", "1 måned"]]), MuiHelpers_createElement(MenuItem, [["value", PeriodOption__ToId(new PeriodOption(2, []))], ["children", "7 dager"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
            let matchValue, elems_5;
            return append((matchValue = model_1.Summary, (matchValue != null) ? singleton(drawSummary(model_1, dispatch, matchValue)) : singleton(createElement("div", createObj(ofArray([["style", {}], (elems_5 = [LoadingIndicator_skeleton(ofArray([["height", 48], ["marginBottom", 5], ["maxWidth", 250]])), LoadingIndicator_skeleton(ofArray([["minHeight", 210], ["height", 20 + "vh"]])), LoadingIndicator_skeleton(ofArray([["height", 3 + "rem"], ["marginBottom", 5], ["maxWidth", 300]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))))), delay(() => {
                let elems_6;
                return singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    marginTop: 10,
                    fontSize: Dimensions_TextSmall,
                    fontStyle: "italic",
                }], (elems_6 = [createElement("span", {
                    children: ["Denne oppsummeringen er AI-generert av ChatGPT fra børsmeldingen. Med forbehold om feil."],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))));
            }));
        }));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

