import { Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { anonRecord_type, string_type, option_type, float64_type, int32_type, record_type, bool_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";

export const disclaimerId = "disclaimer";

export class DisclaimerStorage extends Record {
    constructor(Accepted) {
        super();
        this.Accepted = Accepted;
    }
}

export function DisclaimerStorage_$reflection() {
    return record_type("LocalStorage.DisclaimerStorage", [], DisclaimerStorage, () => [["Accepted", bool_type]]);
}

export function hasAcceptedDisclaimer() {
    try {
        const x = localStorage.getItem(disclaimerId);
        const matchValue = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(DisclaimerStorage_$reflection(), undefined, undefined)), x);
        return (matchValue.tag === 1) ? false : matchValue.fields[0].Accepted;
    }
    catch (matchValue_1) {
        return false;
    }
}

export function acceptDisclaimer() {
    try {
        let x;
        const value = new DisclaimerStorage(true);
        x = toString(4, Auto_generateBoxedEncoder_437914C6(DisclaimerStorage_$reflection(), undefined, undefined, undefined)(value));
        localStorage.setItem(disclaimerId, x);
    }
    catch (matchValue) {
    }
}

export class ReviewDraft extends Record {
    constructor(Rating, TargetPrice, PeriodType, PeriodSpan, PeriodDate, PeriodAllTime, Comment$, CurrentStep, LastUpdated) {
        super();
        this.Rating = (Rating | 0);
        this.TargetPrice = TargetPrice;
        this.PeriodType = (PeriodType | 0);
        this.PeriodSpan = PeriodSpan;
        this.PeriodDate = PeriodDate;
        this.PeriodAllTime = PeriodAllTime;
        this.Comment = Comment$;
        this.CurrentStep = (CurrentStep | 0);
        this.LastUpdated = LastUpdated;
    }
}

export function ReviewDraft_$reflection() {
    return record_type("LocalStorage.ReviewDraft", [], ReviewDraft, () => [["Rating", int32_type], ["TargetPrice", option_type(float64_type)], ["PeriodType", int32_type], ["PeriodSpan", option_type(anonRecord_type(["Num", int32_type], ["Value", string_type]))], ["PeriodDate", option_type(string_type)], ["PeriodAllTime", bool_type], ["Comment", string_type], ["CurrentStep", int32_type], ["LastUpdated", string_type]]);
}

function getDraftId(assetId) {
    return `draft-${assetId}`;
}

export function tryGetReviewDraft(assetId) {
    try {
        const draftId = getDraftId(assetId);
        return fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ReviewDraft_$reflection(), undefined, undefined)), localStorage.getItem(draftId));
    }
    catch (matchValue) {
        return new FSharpResult$2(1, [""]);
    }
}

export function storeDraft(assetId, x) {
    try {
        const draftId = getDraftId(assetId);
        const x_1 = toString(2, Auto_generateBoxedEncoder_437914C6(ReviewDraft_$reflection(), undefined, undefined, undefined)(x));
        localStorage.setItem(draftId, x_1);
    }
    catch (matchValue) {
    }
}

export function deleteDraft(assetId) {
    try {
        const key = getDraftId(assetId);
        localStorage.removeItem(key);
    }
    catch (matchValue) {
    }
}

export class FeedPreferences extends Record {
    constructor(IncludeShort) {
        super();
        this.IncludeShort = IncludeShort;
    }
}

export function FeedPreferences_$reflection() {
    return record_type("LocalStorage.FeedPreferences", [], FeedPreferences, () => [["IncludeShort", bool_type]]);
}

export function tryGetFeedPreferences() {
    try {
        return fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(FeedPreferences_$reflection(), undefined, undefined)), localStorage.getItem("aksjeio-feed-prefs"));
    }
    catch (matchValue) {
        return new FSharpResult$2(1, [""]);
    }
}

export function storeFeedPreference(x) {
    try {
        const x_1 = toString(2, Auto_generateBoxedEncoder_437914C6(FeedPreferences_$reflection(), undefined, undefined, undefined)(x));
        localStorage.setItem("aksjeio-feed-prefs", x_1);
    }
    catch (matchValue) {
    }
}

export function deleteFeedPreference() {
    try {
        localStorage.removeItem("aksjeio-feed-prefs");
    }
    catch (matchValue) {
    }
}

