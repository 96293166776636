
export const Dimensions_TextSmall = 0.85 + "rem";

export const Dimensions_TextExtraSmall = 0.7 + "rem";

export const Dimensions_DefaultPadding = 2 + "vw";

export const Dimensions_DefaultGap = 1 + "vw";

export const DefaultBorder = ["border", (((1 + "px ") + "solid") + " ") + "#e1e1e1"];

