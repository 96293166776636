import { createElement } from "react";
import React from "react";
import { useReact_useEffectOnce_3A5B6456 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import * as gad from "../../Content/gad.js";
import { empty, singleton, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

export function FeedEntry() {
    const isProd = window.location.host === "www.aksje.io";
    useReact_useEffectOnce_3A5B6456(() => {
        if (isProd) {
            gad.handleAds();
        }
    });
    const children = toList(delay(() => (isProd ? singleton(createElement("ins", {
        className: "adsbygoogle",
        style: {
            display: "block",
        },
        "data-ad-format": "fluid",
        "data-ad-layout-key": "-f9+5v+4m-d8+7b",
        "data-ad-client": "ca-pub-4939860990005599",
        "data-ad-slot": "2675278532",
    })) : empty())));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function AssetBanner() {
    const isProd = window.location.host === "www.aksje.io";
    useReact_useEffectOnce_3A5B6456(() => {
        if (isProd) {
            gad.handleAds();
        }
    });
    const children = toList(delay(() => (isProd ? singleton(createElement("ins", {
        className: "adsbygoogle",
        style: {
            display: "block",
        },
        "data-ad-format": "fluid",
        "data-ad-client": "ca-pub-4939860990005599",
        "data-ad-slot": "1540545867",
        "data-ad-format": "auto",
        "data-full-width-responsive": true,
    })) : empty())));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function SquareAd() {
    const isProd = window.location.host === "www.aksje.io";
    useReact_useEffectOnce_3A5B6456(() => {
        if (isProd) {
            gad.handleAds();
        }
    });
    const children = toList(delay(() => (isProd ? singleton(createElement("ins", {
        className: "adsbygoogle",
        style: {
            display: "block",
        },
        "data-ad-client": "ca-pub-4939860990005599",
        "data-ad-slot": "8032660380",
        "data-ad-format": "auto",
        "data-full-width-responsive": true,
    })) : empty())));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function InsiderTradeOverview() {
    const isProd = window.location.host === "www.aksje.io";
    useReact_useEffectOnce_3A5B6456(() => {
        if (isProd) {
            gad.handleAds();
        }
    });
    const children = toList(delay(() => (isProd ? singleton(createElement("ins", {
        className: "adsbygoogle",
        style: {
            display: "block",
        },
        "data-ad-client": "ca-pub-4939860990005599",
        "data-ad-slot": "7720980173",
        "data-ad-format": "auto",
        "data-full-width-responsive": "true",
    })) : empty())));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

