import { toString as toString_1, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { class_type, int32_type, union_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { maxValue, today, date, compare, addYears, addMonths, now, addDays, toString } from "../fable_modules/fable-library-js.4.17.0/Date.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.17.0/String.js";

export class MembershipStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NotMember", "Pending", "Member"];
    }
}

export function MembershipStatus_$reflection() {
    return union_type("Shared.MembershipStatus", [], MembershipStatus, () => [[], [], []]);
}

export function MembershipStatus__ToInt(this$) {
    switch (this$.tag) {
        case 1:
            return 2;
        case 2:
            return 3;
        default:
            return 1;
    }
}

export function MembershipStatus_FromInt_Z524259A4(x) {
    switch (x) {
        case 2:
            return new MembershipStatus(1, []);
        case 3:
            return new MembershipStatus(2, []);
        default:
            return new MembershipStatus(0, []);
    }
}

export class SummaryMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Overlap", "PostedAfter", "PostedBefore", "PostedWithin", "All"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Reviews posted after" : ((this$.tag === 2) ? "Reviews posted before" : ((this$.tag === 3) ? "Reviews posted within" : ((this$.tag === 4) ? "All time" : "Overlapping reviews")));
    }
}

export function SummaryMode_$reflection() {
    return union_type("Shared.SummaryMode", [], SummaryMode, () => [[], [], [], [], []]);
}

export function SummaryMode__ToInt(this$) {
    switch (this$.tag) {
        case 1:
            return 2;
        case 2:
            return 3;
        case 3:
            return 4;
        case 4:
            return 5;
        default:
            return 1;
    }
}

export function SummaryMode_FromInt_Z524259A4(x) {
    switch (x) {
        case 1:
            return new SummaryMode(0, []);
        case 2:
            return new SummaryMode(1, []);
        case 3:
            return new SummaryMode(2, []);
        case 4:
            return new SummaryMode(3, []);
        default:
            return new SummaryMode(4, []);
    }
}

export class StockRating extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Buy", "WeakBuy", "Hold", "WeakSell", "Sell"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Svakt kjøp" : ((this$.tag === 2) ? "Hold" : ((this$.tag === 3) ? "Svakt salg" : ((this$.tag === 4) ? "Selg" : "Kjøp")));
    }
}

export function StockRating_$reflection() {
    return union_type("Shared.StockRating", [], StockRating, () => [[], [], [], [], []]);
}

export function StockRating__ToInt(this$) {
    switch (this$.tag) {
        case 1:
            return 70;
        case 2:
            return 50;
        case 3:
            return 30;
        case 4:
            return 10;
        default:
            return 90;
    }
}

export function StockRating_FromInt_Z524259A4(x) {
    let y_1, y_2, y_3;
    if (x > 80) {
        return new StockRating(0, []);
    }
    else if ((y_1 = (x | 0), (y_1 <= 80) && (y_1 > 60))) {
        return new StockRating(1, []);
    }
    else if ((y_2 = (x | 0), (y_2 <= 60) && (y_2 > 40))) {
        return new StockRating(2, []);
    }
    else if ((y_3 = (x | 0), (y_3 <= 40) && (y_3 > 20))) {
        return new StockRating(3, []);
    }
    else {
        return new StockRating(4, []);
    }
}

export function StockRating__ToColorCode(this$) {
    switch (this$.tag) {
        case 1:
            return "#92f274";
        case 2:
            return "#74bef2";
        case 3:
            return "#f28574";
        case 4:
            return "#cc3608";
        default:
            return "#36cc08";
    }
}

export function StockRating__ToTextColorCode(this$) {
    switch (this$.tag) {
        case 1:
            return "#fff";
        case 2:
            return "#fff";
        case 3:
            return "#fff";
        case 4:
            return "#f6f6f6";
        default:
            return "#f6f6f6";
    }
}

export function StockRating__ToThreshold(this$) {
    switch (this$.tag) {
        case 1:
            return [61, 80];
        case 2:
            return [41, 60];
        case 3:
            return [21, 40];
        case 4:
            return [0, 20];
        default:
            return [81, 100];
    }
}

export class ReportReason extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Spam", "Undocumented", "RacistContent", "SexualContent", "Picture", "GroupRules", "Other"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Udokumenterte/falske påstander" : ((this$.tag === 2) ? "Rasisme/hatytringer" : ((this$.tag === 3) ? "Seksuelt innhold" : ((this$.tag === 4) ? "Upassende/seksuelt/hatfult bilde" : ((this$.tag === 5) ? "Brudd på grupperegler" : ((this$.tag === 6) ? "Annet" : "Spam/ikke relevant innhold")))));
    }
}

export function ReportReason_$reflection() {
    return union_type("Shared.ReportReason", [], ReportReason, () => [[], [], [], [], [], [], []]);
}

export function ReportReason__ToInt(this$) {
    switch (this$.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        case 5:
            return 5;
        case 6:
            return 6;
        default:
            return 0;
    }
}

export function ReportReason_FromInt_Z524259A4(x) {
    switch (x) {
        case 0:
            return new ReportReason(0, []);
        case 1:
            return new ReportReason(1, []);
        case 2:
            return new ReportReason(2, []);
        case 3:
            return new ReportReason(3, []);
        case 4:
            return new ReportReason(4, []);
        case 5:
            return new ReportReason(5, []);
        default:
            return new ReportReason(6, []);
    }
}

export function ReportReason_AsArray() {
    return [new ReportReason(0, []), new ReportReason(1, []), new ReportReason(2, []), new ReportReason(3, []), new ReportReason(4, []), new ReportReason(6, [])];
}

export function ReportReason_AsArrayGroup() {
    return [new ReportReason(0, []), new ReportReason(1, []), new ReportReason(2, []), new ReportReason(3, []), new ReportReason(4, []), new ReportReason(5, []), new ReportReason(6, [])];
}

export class PeriodSpan extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Days", "Weeks", "Months", "Quarters", "Years"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? (`For ${this$.fields[0]} uker`) : ((this$.tag === 2) ? (`For ${this$.fields[0]} måneder`) : ((this$.tag === 3) ? (`For ${this$.fields[0]} kvartal`) : ((this$.tag === 4) ? (`For ${this$.fields[0]} år`) : (`For ${this$.fields[0]} dager`))));
    }
}

export function PeriodSpan_$reflection() {
    return union_type("Shared.PeriodSpan", [], PeriodSpan, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export function PeriodSpan__ToValue(this$) {
    switch (this$.tag) {
        case 1:
            return "weeks";
        case 2:
            return "months";
        case 3:
            return "quarters";
        case 4:
            return "years";
        default:
            return "days";
    }
}

export function PeriodSpan__ToNumber(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0] | 0;
        case 2:
            return this$.fields[0] | 0;
        case 3:
            return this$.fields[0] | 0;
        case 4:
            return this$.fields[0] | 0;
        default:
            return this$.fields[0] | 0;
    }
}

export function PeriodSpan__Validate(this$) {
    switch (this$.tag) {
        case 1: {
            const x_1 = this$.fields[0] | 0;
            if (x_1 >= 1) {
                return x_1 <= 100;
            }
            else {
                return false;
            }
        }
        case 2: {
            const x_2 = this$.fields[0] | 0;
            if (x_2 >= 1) {
                return x_2 <= 100;
            }
            else {
                return false;
            }
        }
        case 3: {
            const x_3 = this$.fields[0] | 0;
            if (x_3 >= 1) {
                return x_3 <= 40;
            }
            else {
                return false;
            }
        }
        case 4: {
            const x_4 = this$.fields[0] | 0;
            if (x_4 >= 1) {
                return x_4 <= 20;
            }
            else {
                return false;
            }
        }
        default: {
            const x = this$.fields[0] | 0;
            if (x >= 1) {
                return x <= 1000;
            }
            else {
                return false;
            }
        }
    }
}

export function PeriodSpan__ToDateString(this$) {
    switch (this$.tag) {
        case 1:
            return toString(addDays(now(), this$.fields[0] * 7), "yyyy-MM-dd");
        case 2:
            return toString(addMonths(now(), this$.fields[0]), "yyyy-MM-dd");
        case 3:
            return toString(addMonths(now(), this$.fields[0] * 3), "yyyy-MM-dd");
        case 4:
            return toString(addYears(now(), this$.fields[0]), "yyyy-MM-dd");
        default:
            return toString(addDays(now(), this$.fields[0]), "yyyy-MM-dd");
    }
}

export function PeriodSpan__ToDate(this$) {
    switch (this$.tag) {
        case 1:
            return addDays(now(), this$.fields[0] * 7);
        case 2:
            return addMonths(now(), this$.fields[0]);
        case 3:
            return addMonths(now(), this$.fields[0] * 3);
        case 4:
            return addYears(now(), this$.fields[0]);
        default:
            return addDays(now(), this$.fields[0]);
    }
}

export class PeriodType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Span", "ExactDate", "AllTime"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                const arg = toString(this$.fields[0], "yyyy-MM-dd");
                return toText(printf("til %s"))(arg);
            }
            case 2:
                return "All tid";
            default:
                return toString_1(this$.fields[0]);
        }
    }
}

export function PeriodType_$reflection() {
    return union_type("Shared.PeriodType", [], PeriodType, () => [[["Item", PeriodSpan_$reflection()]], [["Item", class_type("System.DateTime")]], []]);
}

export function PeriodType__ToValue(this$) {
    switch (this$.tag) {
        case 1:
            return "date";
        case 2:
            return "alltime";
        default:
            return "span";
    }
}

export function PeriodType__UpdateSpanLength_Z524259A4(this$, x) {
    if (this$.tag === 0) {
        const y = this$.fields[0];
        return new PeriodType(0, [(y.tag === 1) ? (new PeriodSpan(1, [x])) : ((y.tag === 2) ? (new PeriodSpan(2, [x])) : ((y.tag === 3) ? (new PeriodSpan(3, [x])) : ((y.tag === 4) ? (new PeriodSpan(4, [x])) : (new PeriodSpan(0, [x])))))]);
    }
    else {
        return this$;
    }
}

export function PeriodType__IncrementSpanLength(this$) {
    if (this$.tag === 0) {
        const y = this$.fields[0];
        return new PeriodType(0, [(y.tag === 1) ? (new PeriodSpan(1, [y.fields[0] + 1])) : ((y.tag === 2) ? (new PeriodSpan(2, [y.fields[0] + 1])) : ((y.tag === 3) ? (new PeriodSpan(3, [y.fields[0] + 1])) : ((y.tag === 4) ? (new PeriodSpan(4, [y.fields[0] + 1])) : (new PeriodSpan(0, [y.fields[0] + 1])))))]);
    }
    else {
        return this$;
    }
}

export function PeriodType__DecrementSpanLength(this$) {
    let x_1, x_2, x_3, x_4, x;
    if (this$.tag === 0) {
        const y = this$.fields[0];
        return new PeriodType(0, [(y.tag === 1) ? ((x_1 = (y.fields[0] | 0), (x_1 > 1) ? (new PeriodSpan(1, [x_1 - 1])) : (new PeriodSpan(1, [x_1])))) : ((y.tag === 2) ? ((x_2 = (y.fields[0] | 0), (x_2 > 1) ? (new PeriodSpan(2, [x_2 - 1])) : (new PeriodSpan(2, [x_2])))) : ((y.tag === 3) ? ((x_3 = (y.fields[0] | 0), (x_3 > 1) ? (new PeriodSpan(3, [x_3 - 1])) : (new PeriodSpan(3, [x_3])))) : ((y.tag === 4) ? ((x_4 = (y.fields[0] | 0), (x_4 > 1) ? (new PeriodSpan(4, [x_4 - 1])) : (new PeriodSpan(4, [x_4])))) : ((x = (y.fields[0] | 0), (x > 1) ? (new PeriodSpan(0, [x - 1])) : (new PeriodSpan(0, [x])))))))]);
    }
    else {
        return this$;
    }
}

export function PeriodType__SetExactDate_7F9DDECF(this$, x) {
    if (this$.tag === 1) {
        if (compare(date(x), today()) > 0) {
            return new PeriodType(1, [x]);
        }
        else {
            return this$;
        }
    }
    else {
        return this$;
    }
}

export function PeriodType__Morph_Z721C83C5(this$, x) {
    switch (x) {
        case "days":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(0, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(0, [1])]);
            }
        case "weeks":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(1, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(1, [1])]);
            }
        case "months":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(2, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(2, [1])]);
            }
        case "quarters":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(3, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(3, [1])]);
            }
        case "years":
            if (this$.tag === 0) {
                return new PeriodType(0, [new PeriodSpan(4, [PeriodSpan__ToNumber(this$.fields[0])])]);
            }
            else {
                return new PeriodType(0, [new PeriodSpan(4, [1])]);
            }
        case "exact":
            if (this$.tag === 1) {
                return this$;
            }
            else {
                return new PeriodType(1, [addDays(today(), 1)]);
            }
        default:
            return this$;
    }
}

export function PeriodType__Validate(this$) {
    switch (this$.tag) {
        case 0:
            return PeriodSpan__Validate(this$.fields[0]);
        case 1:
            return compare(date(this$.fields[0]), date(addDays(now(), 1))) >= 0;
        default:
            return true;
    }
}

export function PeriodType__ToDateString(this$) {
    switch (this$.tag) {
        case 0:
            return PeriodSpan__ToDateString(this$.fields[0]);
        case 1:
            return toString(this$.fields[0], "yyyy-MM-dd");
        default:
            return "alltime";
    }
}

export function PeriodType__ToDate(this$) {
    switch (this$.tag) {
        case 0:
            return PeriodSpan__ToDate(this$.fields[0]);
        case 1:
            return this$.fields[0];
        default:
            return maxValue();
    }
}

