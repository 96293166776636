import { Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { float64_type, obj_type, record_type, string_type, array_type, bool_type, option_type, int32_type, union_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { DataServer_Short_ShortPosition_$reflection, Short_Event_$reflection, Short_ShortSummary_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { comparePrimitives, equals, createObj, uncurry2 } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ResponsiveContainer, ComposedChart, LineChart, Line, BarChart, Bar, Legend, Tooltip, YAxis, XAxis } from "recharts";
import { toShortAmount, utcDateToLocalDate } from "../Utils.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { sortByDescending, sortBy, map as map_1 } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { compare, parse } from "../fable_modules/fable-library-js.4.17.0/Date.js";
import { createElement } from "react";
import React from "react";
import { LoadingIndicator_skeleton, Alert_snackError, groupButtonRight, groupButtonCenter, groupButtonLeft } from "../ViewHelpers.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { Dimensions_TextSmall } from "../Design.js";
import { DataServerBaseUrl } from "../Session.js";

class ReportPeriod extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Quarter", "Month", "HalfYear", "Year"];
    }
}

function ReportPeriod_$reflection() {
    return union_type("Investfora.AssetShort.ReportPeriod", [], ReportPeriod, () => [[], [], [], []]);
}

function ReportPeriod_FromInt_Z524259A4(x) {
    switch (x) {
        case 2:
            return new ReportPeriod(1, []);
        case 3:
            return new ReportPeriod(2, []);
        case 4:
            return new ReportPeriod(3, []);
        default:
            return new ReportPeriod(0, []);
    }
}

function ReportPeriod__ToInt(this$) {
    switch (this$.tag) {
        case 1:
            return 2;
        case 2:
            return 3;
        case 3:
            return 4;
        default:
            return 1;
    }
}

class ReportInterval extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Weekly", "Monthly", "ByEvent"];
    }
}

function ReportInterval_$reflection() {
    return union_type("Investfora.AssetShort.ReportInterval", [], ReportInterval, () => [[], [], []]);
}

function ReportInterval_FromInt_Z524259A4(x) {
    switch (x) {
        case 2:
            return new ReportInterval(1, []);
        case 3:
            return new ReportInterval(2, []);
        default:
            return new ReportInterval(0, []);
    }
}

function ReportInterval__ToInt(this$) {
    switch (this$.tag) {
        case 1:
            return 2;
        case 2:
            return 3;
        default:
            return 1;
    }
}

class ReportPresentation extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NumShares", "Percentage", "Combined"];
    }
}

function ReportPresentation_$reflection() {
    return union_type("Investfora.AssetShort.ReportPresentation", [], ReportPresentation, () => [[], [], []]);
}

class Model extends Record {
    constructor(AssetId, Summary, SelectedPeriod, SelectedInterval, Presentation, MissingInfo, Entries, ErrorMsg) {
        super();
        this.AssetId = (AssetId | 0);
        this.Summary = Summary;
        this.SelectedPeriod = SelectedPeriod;
        this.SelectedInterval = SelectedInterval;
        this.Presentation = Presentation;
        this.MissingInfo = MissingInfo;
        this.Entries = Entries;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.AssetShort.Model", [], Model, () => [["AssetId", int32_type], ["Summary", option_type(Short_ShortSummary_$reflection())], ["SelectedPeriod", ReportPeriod_$reflection()], ["SelectedInterval", ReportInterval_$reflection()], ["Presentation", ReportPresentation_$reflection()], ["MissingInfo", bool_type], ["Entries", option_type(array_type(Short_Event_$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SummaryResponse", "EventsResponse", "UpdatePeriod", "UpdateInterval", "UpdatePresentation", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.AssetShort.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Short_ShortSummary_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Short_ShortSummary_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Short_Event_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Short_Event_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", ReportPeriod_$reflection()]], [["Item", ReportInterval_$reflection()]], [["Item", ReportPresentation_$reflection()]], []]);
}

function init(assetId) {
    return new Model(assetId, undefined, new ReportPeriod(0, []), new ReportInterval(2, []), new ReportPresentation(0, []), false, undefined, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.AssetId, model.Summary, model.SelectedPeriod, model.SelectedInterval, model.Presentation, model.MissingInfo, model.Entries, "Kunne ikke laste short historikk. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.AssetId, model.Summary, model.SelectedPeriod, model.SelectedInterval, model.Presentation, model.MissingInfo, res_1.fields[0], model.ErrorMsg);
            }
        }
        case 2:
            return new Model(model.AssetId, model.Summary, msg.fields[0], model.SelectedInterval, model.Presentation, model.MissingInfo, model.Entries, model.ErrorMsg);
        case 3:
            return new Model(model.AssetId, model.Summary, model.SelectedPeriod, msg.fields[0], model.Presentation, model.MissingInfo, model.Entries, model.ErrorMsg);
        case 4:
            return new Model(model.AssetId, model.Summary, model.SelectedPeriod, model.SelectedInterval, msg.fields[0], model.MissingInfo, model.Entries, model.ErrorMsg);
        case 5:
            return new Model(model.AssetId, model.Summary, model.SelectedPeriod, model.SelectedInterval, model.Presentation, model.MissingInfo, model.Entries, undefined);
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                let matchResult, y_1;
                if (err.tag === 2) {
                    if ((err.fields[0].status) === 404) {
                        matchResult = 0;
                        y_1 = err.fields[0];
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return new Model(model.AssetId, model.Summary, model.SelectedPeriod, model.SelectedInterval, model.Presentation, true, model.Entries, model.ErrorMsg);
                    default:
                        return new Model(model.AssetId, model.Summary, model.SelectedPeriod, model.SelectedInterval, model.Presentation, model.MissingInfo, model.Entries, "Kunne ikke laste short-informasjon. Vennligst prøv igjen senere.");
                }
            }
            else {
                return new Model(model.AssetId, res.fields[0], model.SelectedPeriod, model.SelectedInterval, model.Presentation, model.MissingInfo, model.Entries, model.ErrorMsg);
            }
        }
    }
}

function fetchShort(assetId, period, interval, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const p = (period.tag === 1) ? "?period=month" : ((period.tag === 2) ? "?period=half-year" : ((period.tag === 3) ? "?period=year" : "?period=quarter"));
        const i = (interval.tag === 1) ? "&interval=monthly" : ((interval.tag === 2) ? "&interval=by-event" : "&interval=weekly");
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Short_Event_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/asset/short/${assetId}${p}${i}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Short_Event_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(1, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchSummary(assetId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(Short_ShortSummary_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/asset/short/summary/${assetId}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Short_ShortSummary_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

class Point extends Record {
    constructor(Date$, Aksjer, Prosent) {
        super();
        this.Date = Date$;
        this.Aksjer = (Aksjer | 0);
        this.Prosent = Prosent;
    }
}

function Point_$reflection() {
    return record_type("Investfora.AssetShort.Point", [], Point, () => [["Date", string_type], ["Aksjer", int32_type], ["Prosent", float64_type]]);
}

function numSharesChart(data) {
    let elements;
    const properties_5 = ofArray([["width", 450], ["height", 300], ["data", data], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(5, 0),
        left: defaultArg(10, 0),
        bottom: defaultArg(20, 0),
    }], (elements = ofArray([Interop_reactApi.createElement(XAxis, {
        dataKey: "Date",
        tickFormatter: (tick) => (`${utcDateToLocalDate(tick)}`),
    }), Interop_reactApi.createElement(YAxis, {
        yAxisId: "left",
        tickFormatter: toShortAmount,
    }), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(Legend, {}), Interop_reactApi.createElement(Bar, {
        dataKey: "Aksjer",
        fill: "#73AB84",
        yAxisId: "left",
    })]), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]);
    return Interop_reactApi.createElement(BarChart, createObj(properties_5));
}

function percentageChart(data) {
    let elements;
    const properties_5 = ofArray([["width", 450], ["height", 300], ["data", data], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(5, 0),
        left: defaultArg(10, 0),
        bottom: defaultArg(20, 0),
    }], (elements = ofArray([Interop_reactApi.createElement(XAxis, {
        dataKey: "Date",
        tickFormatter: (tick) => (`${utcDateToLocalDate(tick)}`),
    }), Interop_reactApi.createElement(YAxis, {
        tickFormatter: (tick_1) => (`${tick_1}%`),
    }), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(Legend, {}), Interop_reactApi.createElement(Line, {
        dataKey: "Prosent",
        stroke: "#5F5566",
        strokeWidth: 3,
    })]), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]);
    return Interop_reactApi.createElement(LineChart, createObj(properties_5));
}

function combinedChart(data) {
    let elements;
    const properties_7 = ofArray([["width", 450], ["height", 300], ["data", data], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(10, 0),
        left: defaultArg(10, 0),
        bottom: defaultArg(20, 0),
    }], (elements = ofArray([Interop_reactApi.createElement(XAxis, {
        dataKey: "Date",
        tickFormatter: (tick) => (`${utcDateToLocalDate(tick)}`),
    }), Interop_reactApi.createElement(YAxis, {
        yAxisId: "left",
        tickFormatter: toShortAmount,
    }), Interop_reactApi.createElement(YAxis, {
        yAxisId: "right",
        orientation: "right",
        tickFormatter: (tick_2) => (`${tick_2}%`),
    }), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(Legend, {}), Interop_reactApi.createElement(Bar, {
        dataKey: "Aksjer",
        fill: "#73AB84",
        yAxisId: "left",
    }), Interop_reactApi.createElement(Line, {
        dataKey: "Prosent",
        stroke: "#5F5566",
        strokeWidth: 3,
        yAxisId: "right",
    })]), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]);
    return Interop_reactApi.createElement(ComposedChart, createObj(properties_7));
}

function drawGraph(x, p, dispatch) {
    let elems_2, elems, properties_2, elems_1;
    const data = map_1((y_2) => (new Point(y_2.Date, y_2.Shares, y_2.Points / 100)), sortBy((y) => parse(y.Date), x, {
        Compare: compare,
    }));
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_2 = [createElement("div", createObj(ofArray([["style", {
        minHeight: 210,
        height: 20 + "vh",
    }], (elems = [(properties_2 = ofArray([["minWidth", 250], ["minHeight", 210], ["children", (p.tag === 1) ? percentageChart(data) : ((p.tag === 2) ? combinedChart(data) : numSharesChart(data))]]), Interop_reactApi.createElement(ResponsiveContainer, createObj(properties_2)))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
    }], (elems_1 = [groupButtonLeft(equals(p, new ReportPresentation(0, [])), (_arg) => {
        dispatch(new Message(4, [new ReportPresentation(0, [])]));
    }, ["Antall aksjer"]), groupButtonCenter(equals(p, new ReportPresentation(1, [])), (_arg_1) => {
        dispatch(new Message(4, [new ReportPresentation(1, [])]));
    }, ["Prosent"]), groupButtonRight(equals(p, new ReportPresentation(2, [])), (_arg_2) => {
        dispatch(new Message(4, [new ReportPresentation(2, [])]));
    }, ["Kombinert"])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])));
}

function drawMissingInfo() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {}], (elems = [createElement("span", {
        children: "Ingen short data for valgt periode",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])));
}

export function AssetShort(assetShortInputProps) {
    let elems_9;
    const assetId = assetShortInputProps.assetId;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(assetId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchSummary(assetId, dispatch);
        fetchShort(assetId, model_1.SelectedPeriod, model_1.SelectedInterval, dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        padding: 2 + "vw",
    }], (elems_9 = toList(delay(() => append(singleton(Alert_snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(5, []));
    })), delay(() => {
        let elems_8, children;
        const matchValue = model_1.Entries;
        if (matchValue != null) {
            const x = matchValue;
            return singleton(createElement("div", createObj(ofArray([["style", {}], (elems_8 = toList(delay(() => {
                let elems_6, elems, elems_5, elements, elems_1, elements_1, elems_3;
                return append(singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    alignItems: "flex-end",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginBottom: 20,
                }], (elems_6 = [createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                }], (elems = [createElement("h2", {
                    style: {
                        fontSize: 2 + "rem",
                        fontWeight: 500,
                        margin: 0,
                    },
                    children: "Short-utvikling",
                })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    justifyContent: "flex-end",
                }], (elems_5 = [MuiHelpers_createElement(FormControl, [(elements = [MuiHelpers_createElement(InputLabel, [["variant", "standard"], ["id", "short-period-select-label-id"], ["children", "Periode"]]), MuiHelpers_createElement(Select, [["id", "short-period-select-id"], ["labelId", "short-period-select-label-id"], ["label", "Periode"], ["variant", "standard"], ["value", ReportPeriod__ToInt(model_1.SelectedPeriod)], ["onChange", (e_1, _arg_2) => {
                    const e = e_1.target.value;
                    try {
                        const p = ReportPeriod_FromInt_Z524259A4(e);
                        dispatch(new Message(2, [p]));
                        fetchShort(model_1.AssetId, p, model_1.SelectedInterval, dispatch);
                    }
                    catch (matchValue_1) {
                    }
                }], (elems_1 = [MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(1, []))], ["children", "En måned"]]), MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(0, []))], ["children", "3 måneder"]]), MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(2, []))], ["children", "Siste 6 måneder"]]), MuiHelpers_createElement(MenuItem, [["value", ReportPeriod__ToInt(new ReportPeriod(3, []))], ["children", "Ett år"]])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]), MuiHelpers_createElement(FormControl, [["style", {
                    marginLeft: 1 + "vw",
                }], (elements_1 = [MuiHelpers_createElement(InputLabel, [["variant", "standard"], ["id", "short-interval-select-label-id"], ["children", "Intervall"]]), MuiHelpers_createElement(Select, [["id", "short-interval-select-id"], ["labelId", "short-interval-select-label-id"], ["label", "Intervall"], ["variant", "standard"], ["value", ReportInterval__ToInt(model_1.SelectedInterval)], ["onChange", (e_3, _arg_3) => {
                    const e_2 = e_3.target.value;
                    try {
                        const i = ReportInterval_FromInt_Z524259A4(e_2);
                        dispatch(new Message(3, [i]));
                        fetchShort(model_1.AssetId, model_1.SelectedPeriod, i, dispatch);
                    }
                    catch (matchValue_2) {
                    }
                }], (elems_3 = [MuiHelpers_createElement(MenuItem, [["value", ReportInterval__ToInt(new ReportInterval(0, []))], ["children", "Ukentlig"]]), MuiHelpers_createElement(MenuItem, [["value", ReportInterval__ToInt(new ReportInterval(2, []))], ["children", "Etter endring"]])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elements_1))])])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_6))])])))), delay(() => append((x.length === 0) ? singleton(drawMissingInfo()) : singleton(drawGraph(x, model_1.Presentation, dispatch)), delay(() => {
                    let elems_7;
                    return singleton(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        marginTop: 10,
                        fontSize: Dimensions_TextSmall,
                        fontStyle: "italic",
                    }], (elems_7 = [createElement("span", {
                        children: ["Basert på short-posisjoner større eller lik 0.5% av utestående aksjer"],
                    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_7))])]))));
                }))));
            })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_8))])]))));
        }
        else {
            return singleton((children = ofArray([LoadingIndicator_skeleton(ofArray([["height", 48], ["marginBottom", 5], ["maxWidth", 250]])), LoadingIndicator_skeleton(ofArray([["minHeight", 210], ["height", 20 + "vh"]])), LoadingIndicator_skeleton(ofArray([["height", 3 + "rem"], ["marginBottom", 5], ["maxWidth", 300]]))]), createElement("div", {
                children: Interop_reactApi_1.Children.toArray(Array.from(children)),
            })));
        }
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_9))])])));
}

export function TopShort(topShortInputProps) {
    let elems_3, elems_1, elems_2;
    const ticker = topShortInputProps.ticker;
    const market = topShortInputProps.market;
    const patternInput = useFeliz_React__React_useState_Static_1505(undefined);
    useReact_useEffectOnce_3A5B6456(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder, data_1, caseStrategy_2, extra_2;
            return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_Short_ShortPosition_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/short/asset-top/${market}/${ticker}`, properties_3).then((_arg_1) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_Short_ShortPosition_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                            let matchValue;
                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_2), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })()))))).then((_arg_3) => {
                const res = _arg_3;
                if (res.tag === 1) {
                    return Promise.resolve();
                }
                else {
                    patternInput[1](res.fields[0]);
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    });
    return createElement("div", createObj(ofArray([["style", {
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        padding: 2 + "vw",
    }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
    }], (elems_1 = toList(delay(() => append(singleton(createElement("h2", {
        style: {
            fontSize: 2 + "rem",
            fontWeight: 500,
            margin: 0,
        },
        children: "Topp 10 største short",
    })), delay(() => {
        let elems, children_8, children_2, children, children_6;
        const matchValue_1 = patternInput[0];
        if (matchValue_1 != null) {
            const s = matchValue_1;
            return (s.length === 0) ? singleton(createElement("div", createObj(ofArray([["style", {
                textAlign: "center",
                marginTop: 20,
            }], (elems = [createElement("span", {
                children: ["Ingen rapporterte shortposisjoner over 0.5%"],
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])))) : singleton((children_8 = ofArray([(children_2 = singleton_1((children = ofArray([createElement("td", {
                children: ["Institusjon"],
            }), createElement("td", {
                children: ["Prosent"],
            }), createElement("td", {
                children: ["Antall aksjer"],
            })]), createElement("tr", {
                children: Interop_reactApi_1.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: Interop_reactApi_1.Children.toArray(Array.from(children_2)),
            })), (children_6 = map_1((s$0027_1) => {
                const children_4 = ofArray([createElement("td", {
                    children: [s$0027_1.Institution],
                }), createElement("td", {
                    children: [`${s$0027_1.Percent / 100}%`],
                }), createElement("td", {
                    children: [toShortAmount(s$0027_1.NumShares)],
                })]);
                return createElement("tr", {
                    children: Interop_reactApi_1.Children.toArray(Array.from(children_4)),
                });
            }, sortByDescending((s$0027) => s$0027.NumShares, s, {
                Compare: comparePrimitives,
            })), createElement("tbody", {
                children: Interop_reactApi_1.Children.toArray(Array.from(children_6)),
            }))]), createElement("table", {
                children: Interop_reactApi_1.Children.toArray(Array.from(children_8)),
            })));
        }
        else {
            const loadingIndicator = () => LoadingIndicator_skeleton(ofArray([["height", 40], ["width", 100 + "%"], ["marginBottom", 5]]));
            return append(singleton(loadingIndicator()), delay(() => append(singleton(loadingIndicator()), delay(() => singleton(loadingIndicator())))));
        }
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        marginTop: 10,
        fontSize: Dimensions_TextSmall,
        fontStyle: "italic",
    }], (elems_2 = [createElement("span", {
        children: ["Basert på short-posisjoner større eller lik 0.5% av utestående aksjer"],
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])));
}

