import { createElement } from "react";
import { fromFlatEntries } from "./Flatten.js";

export const MuiHelpers_reactElement = createElement;

export const MuiHelpers_reactElementTag = createElement;

export function MuiHelpers_createElement(el, properties) {
    return MuiHelpers_reactElement(el, fromFlatEntries(properties));
}

export function MuiHelpers_createElementTag(tag, properties) {
    return MuiHelpers_reactElementTag(tag, fromFlatEntries(properties));
}

