import { Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, option_type, bool_type, record_type, string_type, union_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { ImageForm_$reflection, ImageForm as ImageForm_1, GenericResponse_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { split } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { item as item_1 } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { createElement } from "react";
import React from "react";
import { cons, empty as empty_1, singleton as singleton_1, item as item_2, length, ofArray } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { uncurry2, createObj } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { map, empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { LoadingIndicator_loadingIndicatorSmall, pillButtonSmall, Dialog_dialogActions, Dialog_dialogContent, Dialog_Dialog, Alert_snackError, Image_circledImage } from "../ViewHelpers.js";
import { rangeDouble } from "../fable_modules/fable-library-js.4.17.0/Range.js";
import { unwrap, map as map_1, defaultArg, some } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import * as imageresize from "../../Content/imageresize.js";
import { useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";

export class ImageSource extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["User"];
    }
}

export function ImageSource_$reflection() {
    return union_type("Investfora.ImageForm.ImageSource", [], ImageSource, () => [[]]);
}

export function ImageSource__UploadPath(this$) {
    return "/api/user/image/upload";
}

class Image extends Record {
    constructor(Data, Filename, FileExt) {
        super();
        this.Data = Data;
        this.Filename = Filename;
        this.FileExt = FileExt;
    }
}

function Image_$reflection() {
    return record_type("Investfora.ImageForm.Image", [], Image, () => [["Data", string_type], ["Filename", string_type], ["FileExt", string_type]]);
}

class Model extends Record {
    constructor(Target, Processing, Image, ErrorMsg, Uploaded) {
        super();
        this.Target = Target;
        this.Processing = Processing;
        this.Image = Image;
        this.ErrorMsg = ErrorMsg;
        this.Uploaded = Uploaded;
    }
}

function Model_$reflection() {
    return record_type("Investfora.ImageForm.Model", [], Model, () => [["Target", ImageSource_$reflection()], ["Processing", bool_type], ["Image", option_type(Image_$reflection())], ["ErrorMsg", option_type(string_type)], ["Uploaded", bool_type]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ImageSelected", "Commit", "CommitResponse", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.ImageForm.Message", [], Message, () => [[["Item1", string_type], ["Item2", string_type], ["Item3", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], []]);
}

function init(x) {
    return new Model(x, false, undefined, undefined, false);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1:
            return new Model(model.Target, true, model.Image, undefined, model.Uploaded);
        case 2: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                return new Model(model.Target, false, model.Image, (err.tag === 2) ? (((err.fields[0].status) === 401) ? "Du har ikke rettigheter til å oppdatere dette bildet." : (((err.fields[0].status) === 413) ? "Valgt bilde er for stort." : "Kunne ikke oppdatere bilde. Vennligst prøv igjen senere.")) : "Kunne ikke oppdatere bilde. Vennligst prøv igjen senere.", model.Uploaded);
            }
            else {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    return model;
                }
                else {
                    return new Model(model.Target, false, model.Image, x_1.Message, model.Uploaded);
                }
            }
        }
        case 3:
            return new Model(model.Target, model.Processing, model.Image, undefined, model.Uploaded);
        default:
            return new Model(model.Target, model.Processing, new Image(msg.fields[0], msg.fields[1], msg.fields[2]), model.ErrorMsg, model.Uploaded);
    }
}

function parseFileExt(fileExt) {
    let x;
    try {
        if ((x = fileExt, (x === "") ? true : (x == null))) {
            return "jpeg";
        }
        else {
            const y = split(fileExt, ["/"], undefined, 0);
            if (y.length > 1) {
                const z = item_1(y.length - 1, y);
                const matchValue = z.toLocaleLowerCase();
                switch (matchValue) {
                    case "jpeg":
                    case "png":
                        return z;
                    default:
                        return "jpeg";
                }
            }
            else {
                return "jpeg";
            }
        }
    }
    catch (matchValue_1) {
        return "jpeg";
    }
}

function uploaded(onClose) {
    const children = ofArray([createElement("span", {
        style: {
            fontSize: 1.5 + "rem",
            marginTop: 10 + "px",
            textAlign: "center",
        },
        children: "Bildet er oppdatert!",
    }), createElement("button", {
        className: "pill-button",
        onClick: (_arg) => {
            onClose(true);
        },
        children: "Lukk",
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function upload(model, dispatch) {
    let elems_1;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 100 + "%",
    }], (elems_1 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Image, (matchValue != null) ? singleton(Image_circledImage(200, matchValue.Data)) : (empty())), delay(() => {
            let elems;
            return append(!model.Processing ? singleton(createElement("label", createObj(ofArray([["style", {
                marginTop: 5,
                marginBottom: 10,
                cursor: "pointer",
                textDecoration: "underline",
                display: "block",
            }], (elems = ["Velg bilde", createElement("input", {
                type: "file",
                accept: "image/png, image/jpeg",
                onChange: (ev) => {
                    let x_1;
                    const fileList = ev.target.files;
                    if (!(fileList == null)) {
                        const x = toList(delay(() => map((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                        try {
                            if (length(x) > 0) {
                                const file_1 = item_2(0, x);
                                const fileExt = parseFileExt(file_1.name);
                                let filename;
                                const matchValue_1 = file_1.name;
                                filename = (((x_1 = matchValue_1, (x_1 === "") ? true : (x_1 == null))) ? (`img.${fileExt}`) : matchValue_1);
                                const reader = new FileReader();
                                reader.addEventListener("load", (_arg) => {
                                    const arg = reader.result;
                                    imageresize.imgScale(arg, 512, 512, (scaled) => {
                                        dispatch(new Message(0, [scaled, filename, fileExt]));
                                    });
                                });
                                reader.readAsDataURL(file_1);
                            }
                        }
                        catch (e) {
                            window.alert(some(e));
                        }
                    }
                },
                style: {
                    display: "none",
                },
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty(), delay(() => singleton(Alert_snackError(model.ErrorMsg, () => {
                dispatch(new Message(3, []));
            }))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function ImageForm(imageFormInputProps) {
    let elems;
    const onClose = imageFormInputProps.onClose;
    const src = imageFormInputProps.src;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(src));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement(Dialog_Dialog, {
        onClose: () => {
            onClose(model_1.Uploaded);
        },
        children: ofArray([Dialog_dialogContent(singleton_1(createElement("div", createObj(ofArray([["style", {
            minWidth: 250,
            minHeight: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }], (elems = toList(delay(() => (model_1.Uploaded ? singleton(uploaded(onClose)) : singleton(upload(model_1, dispatch))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))), Dialog_dialogActions(toList(delay(() => append(singleton(pillButtonSmall(empty_1(), "Lukk", (_arg_4) => {
            onClose(model_1.Uploaded);
        })), delay(() => {
            let elems_1;
            return !model_1.Uploaded ? singleton(createElement("button", createObj(ofArray([["className", "pill-button-small"], ["disabled", model_1.Image == null], ["onClick", (_arg_5) => {
                if (!model_1.Processing) {
                    const matchValue_1 = model_1.Image;
                    if (matchValue_1 != null) {
                        const img_1 = matchValue_1;
                        const img = new ImageForm_1(img_1.Data, img_1.Filename);
                        const dispatch_1 = dispatch;
                        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let body, decoder, data_1, caseStrategy_3, extra_3;
                            dispatch_1(new Message(1, []));
                            const requestPath = ImageSource__UploadPath(model_1.Target);
                            return ((body = Auto_generateBoxedEncoder_437914C6(ImageForm_$reflection(), undefined, undefined, undefined)(img), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                                let properties_2;
                                try {
                                    const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty_1()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                                        let response_1, decoder_1_1;
                                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                            let matchValue;
                                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                    }))));
                                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                                }
                                catch (exn) {
                                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                                }
                            })())))))).then((_arg_2) => {
                                const res = _arg_2;
                                let matchResult;
                                if (res.tag === 0) {
                                    if (res.fields[0].Result === "success") {
                                        matchResult = 0;
                                    }
                                    else {
                                        matchResult = 1;
                                    }
                                }
                                else {
                                    matchResult = 1;
                                }
                                switch (matchResult) {
                                    case 0: {
                                        onClose(true);
                                        return Promise.resolve();
                                    }
                                    default: {
                                        dispatch_1(new Message(2, [res]));
                                        return Promise.resolve();
                                    }
                                }
                            });
                        }));
                        pr_1.then();
                    }
                }
            }], (elems_1 = toList(delay(() => (!model_1.Processing ? singleton("Last opp") : singleton(LoadingIndicator_loadingIndicatorSmall())))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
        })))))]),
    });
}

