import { createElement } from "react";
import React from "react";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { parseAsHtmlString } from "./ContentParser.js";
import { CommentForm, trySetContent } from "./CommentForm.js";
import { uncurry2, createObj } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { obj_type, string_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { Post_$reflection } from "../Shared/ApiDataTypes.js";
import { unwrap, map, defaultArg, some } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Alert_errorAlert } from "../ViewHelpers.js";

export function EditForm(editFormInputProps) {
    let elems_1, elems;
    const onClose = editFormInputProps.onClose;
    const post = editFormInputProps.post;
    const patternInput = useFeliz_React__React_useState_Static_1505(parseAsHtmlString(post.Content));
    const content = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const setError = patternInput_1[1];
    const formId = `edit-${post.Type}-${post.Id}`;
    useReact_useEffectOnce_3A5B6456(() => {
        trySetContent(formId, content);
    });
    return createElement("div", createObj(ofArray([["style", {}], (elems_1 = [createElement(CommentForm, {
        formId: formId,
        html: content,
        onChange: patternInput[1],
    }), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 5,
    }], (elems = [createElement("button", {
        className: "pill-button-small",
        style: {
            marginRight: 10,
        },
        children: "Avbryt",
        onClick: (_arg_4) => {
            onClose(undefined);
        },
    }), createElement("button", {
        className: "pill-button-small",
        children: "Lagre endringer",
        onClick: (_arg_5) => {
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let body, decoder, data_1, caseStrategy_3, extra_3;
                let requestPath;
                const matchValue = post.Type;
                requestPath = ((matchValue === "asset-post") ? toText(printf("/api/asset/post/%i"))(post.Id) : ((matchValue === "review") ? toText(printf("/api/review/%i"))(post.Id) : ""));
                return (requestPath !== "") ? (((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(content), (decoder = Auto_generateBoxedDecoder_Z6670B51(Post_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                    let properties_2;
                    try {
                        const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Post_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                let matchValue_1;
                                return Promise.resolve((matchValue_1 = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_1.fields[0]])])) : (new FSharpResult$2(0, [matchValue_1.fields[0]]))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_2) => (new FSharpResult$2(1, [new FetchError(3, [arg_2])]))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                    }
                })())))))).then((_arg_2) => {
                    const res = _arg_2;
                    if (res.tag === 1) {
                        setError("Could not update post");
                        return Promise.resolve();
                    }
                    else {
                        onClose(res.fields[0].Content);
                        return Promise.resolve();
                    }
                })) : (Promise.resolve());
            }));
            pr_1.then();
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), Alert_errorAlert(patternInput_1[0], () => {
        setError(undefined);
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

