function imgScale (b64, w, h, callback) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var image = new Image();
    image.onload = function(e) {
        if (image.width < w && image.height < h) {
            callback(b64);
        } else {
            var newW = image.width;
            var newH = image.height;
            if (newW > newH) {
                if (newW > w) {
                    newH *= h / newW;
                    newW = w;
                }
            } else {
                if (newH > h) {
                    newW *= w / newH;
                    newH = h;
                }
            }
            canvas.width = newW;
            canvas.height = newH;
            ctx.drawImage(image,
                0, 0, image.width, image.height,
                0, 0, canvas.width, canvas.height
            );
            var content = canvas.toDataURL();
            callback(content);
        }
    };

    image.src = b64;
}
export {imgScale};