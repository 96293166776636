import { int, float, string, int64, object } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { Valuation_AssetKeyNumbers_$reflection, Valuation_AssetKeyNumbers } from "./Shared/ApiDataTypes.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { newGuid } from "./fable_modules/fable-library-js.4.17.0/Guid.js";
import { add } from "./fable_modules/fable-library-js.4.17.0/Map.js";
import { Auto_generateBoxedEncoderCached_437914C6, int64 as int64_1 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, int64 as int64_2 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { empty } from "./fable_modules/Thoth.Json.6.0.0/Extra.fs.js";
import { ExtraCoders } from "./fable_modules/Thoth.Json.6.0.0/Types.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { cons, ofArray, empty as empty_1 } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { obj_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";

export const assetKeyNumbersDecoder = (path_6) => ((v_2) => object((x) => {
    let objectArg_5, objectArg_6, objectArg_7;
    return new Valuation_AssetKeyNumbers((objectArg_5 = x.Required, objectArg_5.Field("NumShares", uncurry2(int64))), (objectArg_6 = x.Required, objectArg_6.Field("LastQuote", (path_2, v) => object((y) => {
        let objectArg, objectArg_1;
        return {
            Date: (objectArg = y.Required, objectArg.Field("Date", string)),
            Price: (objectArg_1 = y.Required, objectArg_1.Field("Price", float)),
        };
    }, path_2, v))), (objectArg_7 = x.Optional, objectArg_7.Field("LastShort", (path_5, v_1) => object((y_1) => {
        let objectArg_2, objectArg_3, objectArg_4;
        return {
            Date: (objectArg_2 = y_1.Required, objectArg_2.Field("Date", string)),
            NumShares: (objectArg_3 = y_1.Required, objectArg_3.Field("NumShares", uncurry2(int))),
            Percentage: (objectArg_4 = y_1.Required, objectArg_4.Field("Percentage", float)),
        };
    }, path_5, v_1))));
}, path_6, v_2));

export function fetchAssetKeyNumbers(assetId, callback) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let copyOfStruct, data_1, caseStrategy_1, extra_4;
        const extraDecoders = new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64_1, int64_2], empty.Coders));
        return ((data_1 = undefined, (caseStrategy_1 = undefined, (extra_4 = extraDecoders, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty_1(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty_1()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_1, extra_4)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/asset/key-numbers/${assetId}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(assetKeyNumbersDecoder, Auto_generateBoxedDecoderCached_Z6670B51(Valuation_AssetKeyNumbers_$reflection(), unwrap(caseStrategy_1), unwrap(extra_4))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))).then((_arg_2) => {
            callback(_arg_2);
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

