import { Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { union_type, string_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { join, replace, substring } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { map, empty, singleton, append } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useReact_useMemo_10C6A43C } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

class ContentElement extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Text", "Link"];
    }
}

function ContentElement_$reflection() {
    return union_type("ContentParser.ContentElement", [], ContentElement, () => [[["Item", string_type]], [["Item1", string_type], ["Item2", string_type]]]);
}

function tryGetLink(x, tagStart, tagEnd) {
    const hrefOpen = (tagEnd + 1) | 0;
    const hrefClose = x.indexOf(")") | 0;
    const tagDiff = (tagEnd - tagStart) | 0;
    if ((hrefClose - hrefOpen) < 2) {
        return undefined;
    }
    else {
        const href = substring(x, hrefOpen + 1, (hrefClose - hrefOpen) - 1);
        const text = substring(x, tagStart + 1, tagDiff - 1);
        if (!((!(href.indexOf("(") >= 0) && !(href.indexOf(" ") >= 0)) && !(href.indexOf("\n") >= 0))) {
            return undefined;
        }
        else {
            return [new ContentElement(1, [href, text]), hrefClose];
        }
    }
}

function parseTag(x) {
    if (x.length === 0) {
        return undefined;
    }
    else if (!(x.indexOf("]") >= 0)) {
        return undefined;
    }
    else {
        const closeIdx = x.indexOf("]") | 0;
        const openIdx = x.indexOf("[") | 0;
        const diff = (closeIdx - openIdx) | 0;
        if ((diff < 2) ? true : (closeIdx > x.length)) {
            return undefined;
        }
        else {
            const beforeOpen = (openIdx > 0) ? (new ContentElement(0, [substring(x, 0, openIdx)])) : undefined;
            const afterOpen = substring(x, openIdx, x.length - openIdx);
            if (afterOpen[diff + 1] === "(") {
                const matchValue = tryGetLink(afterOpen, 0, diff);
                if (matchValue != null) {
                    return [matchValue[0], openIdx + matchValue[1], beforeOpen];
                }
                else {
                    return undefined;
                }
            }
            else {
                return undefined;
            }
        }
    }
}

function parseNext(x_mut, y_mut, iter_mut) {
    parseNext:
    while (true) {
        const x = x_mut, y = y_mut, iter = iter_mut;
        if (!((x.indexOf("[") >= 0) && (x.indexOf("]") >= 0)) ? true : (iter > 100)) {
            return append(y, singleton(new ContentElement(0, [x])));
        }
        else {
            const matchValue = parseTag(x);
            if (matchValue != null) {
                const t = matchValue[0];
                const closeIdx = matchValue[1] | 0;
                const beforeTag = matchValue[2];
                const newList = (beforeTag != null) ? append(y, append(singleton(beforeTag), singleton(t))) : append(y, singleton(t));
                const remaining = (x.length - closeIdx) | 0;
                if (remaining < 3) {
                    return append(newList, singleton(new ContentElement(0, [substring(x, closeIdx + 1, remaining - 1)])));
                }
                else {
                    x_mut = substring(x, closeIdx + 1, remaining - 1);
                    y_mut = newList;
                    iter_mut = (iter + 1);
                    continue parseNext;
                }
            }
            else {
                return append(y, singleton(new ContentElement(0, [x])));
            }
        }
        break;
    }
}

function parseContent(x) {
    return parseNext(replace(replace(x, "&gt;", ">"), "&lt;", "<"), empty(), 0);
}

export function parseAsReactElements(x) {
    const xs_1 = map((y) => {
        if (y.tag === 1) {
            return createElement("a", {
                href: y.fields[0],
                target: "_blank",
                className: "post-link",
                children: y.fields[1],
            });
        }
        else {
            return y.fields[0];
        }
    }, parseContent(x));
    return react.createElement(react.Fragment, {}, ...xs_1);
}

export function parseAsHtmlString(x) {
    return replace(join("", map((y) => {
        if (y.tag === 1) {
            return `<a class="post-link" target="_blank" href="${y.fields[0]}" >${y.fields[1]}</a>`;
        }
        else {
            return y.fields[0];
        }
    }, parseContent(x))), "\n", "<br>");
}

export function Content(contentInputProps) {
    const content = contentInputProps.content;
    const patternInput = useReact_useMemo_10C6A43C(() => [parseAsReactElements(content), [content]]);
    return createElement("div", {
        style: {
            whiteSpace: "pre-wrap",
            overflowX: "hidden",
        },
        children: Interop_reactApi.Children.toArray([patternInput[0]]),
    });
}

