import { makeStyles } from "@mui/styles";
import { useTheme, createTheme, responsiveFontSizes } from "@mui/material/styles";
import { class_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { createObj } from "../fable_modules/fable-library-js.4.17.0/Util.js";

export const StyleImports_makeStyles_get = makeStyles;

export const StyleImports_makeStyles_getWithOpts = makeStyles;

export const StyleImports_makeStyles_obj = makeStyles;

export const StyleImports_makeStyles_objWithOpts = makeStyles;

export const StyleImports_responsiveFontSizes = responsiveFontSizes;

export const StyleImports_responsiveFontSizes_opts = responsiveFontSizes;

export const StyleImports_createTheme_argsArray = createTheme;

export const StyleImports_createTheme_unit = createTheme;

export const StyleImports_useTheme = useTheme;

export class StyleCreator$1 {
    constructor() {
    }
}

export function StyleCreator$1_$reflection(gen0) {
    return class_type("Feliz.MaterialUI.StyleCreator`1", [gen0], StyleCreator$1);
}

export function StyleCreator$1_$ctor() {
    return new StyleCreator$1();
}

/**
 * Use with `makeStyles` etc. when returning an (anonymous) record of style
 * properties or functions. Simply unboxes the input to `string` (which it is
 * at runtime when returned by the JSS styling solution), so that the
 * properties can be used in `className` and `classes` props.
 */
export function StyleCreator$1__create_4EAC9E1E(_, styles) {
    return createObj(styles);
}

/**
 * Use with `makeStyles` etc. when returning an (anonymous) record of style
 * properties or functions. Simply unboxes the input to `string` (which it is
 * at runtime when returned by the JSS styling solution), so that the
 * properties can be used in `className` and `classes` props.
 */
export function StyleCreator$1__create_A7D96BD(_, getStyles) {
    return (arg_1) => createObj(getStyles(arg_1));
}

