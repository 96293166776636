import { Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, union_type, record_type, bool_type, int32_type, option_type, array_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { SearchEntry_$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map, defaultArg, some } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { LoadingIndicator_loadingIndicator, Alert_snackError } from "./ViewHelpers.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { map as map_1 } from "./fable_modules/fable-library-js.4.17.0/Array.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { TextField } from "@mui/material";

class Model extends Record {
    constructor(Term, Results, EventId, Processing, ErrorMsg) {
        super();
        this.Term = Term;
        this.Results = Results;
        this.EventId = (EventId | 0);
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.Search.Model", [], Model, () => [["Term", string_type], ["Results", option_type(array_type(SearchEntry_$reflection()))], ["EventId", int32_type], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateTerm", "SearchResult", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.Search.Message", [], Message, () => [[["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(SearchEntry_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(SearchEntry_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], []]);
}

function init() {
    return new Model("", undefined, 0, false, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Term, model.Results, model.EventId, false, "Something went wrong. Could not perform search.");
            }
            else {
                return new Model(model.Term, res.fields[0], model.EventId, false, undefined);
            }
        }
        case 2:
            return new Model(model.Term, model.Results, model.EventId, model.Processing, undefined);
        default: {
            const x = msg.fields[0];
            if (x.length === 0) {
                return new Model("", undefined, model.EventId, false, undefined);
            }
            else {
                return new Model(x, model.Results, model.EventId + 1, true, model.ErrorMsg);
            }
        }
    }
}

function trySearch(term, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return (term.length > 0) ? (((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(term), (decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(SearchEntry_$reflection()), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/search", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(SearchEntry_$reflection()), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(1, [_arg_2]));
            return Promise.resolve();
        })) : (Promise.resolve());
    }));
    pr_1.then();
}

function typeToName(x) {
    switch (x) {
        case "Stock":
            return "Aksje";
        case "User":
            return "Bruker";
        case "Market":
            return "Marked";
        default:
            return x;
    }
}

function drawResults(model, dispatch) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", "search-result"], (elems_1 = toList(delay(() => append(singleton_1(Alert_snackError(model.ErrorMsg, () => {
        dispatch(new Message(2, []));
    })), delay(() => append(model.Processing ? singleton_1(LoadingIndicator_loadingIndicator()) : empty_1(), delay(() => {
        let elems;
        return singleton_1(createElement("table", createObj(ofArray([["style", {}], (elems = toList(delay(() => {
            let children, xs_3;
            const matchValue = model.Results;
            if (matchValue != null) {
                const x = matchValue;
                return (x.length === 0) ? singleton_1((children = singleton(createElement("td", {
                    children: ["Ingen søkeresultater"],
                })), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))) : singleton_1((xs_3 = map_1((y) => {
                    let children_2, children_4;
                    const children_6 = ofArray([(children_2 = singleton(createElement("a", {
                        href: y.Link,
                        children: y.Name,
                    })), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    })), (children_4 = singleton(createElement("a", {
                        href: y.Link,
                        children: y.Extra,
                    })), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })), createElement("td", {
                        style: {
                            width: 10 + "%",
                        },
                        children: typeToName(y.Type),
                    })]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    });
                }, x), react.createElement(react.Fragment, {}, ...xs_3)));
            }
            else {
                return empty_1();
            }
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Search() {
    let elems_1;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
    }], (elems_1 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", "search-bar"], (elems = [MuiHelpers_createElement(TextField, [["autoFocus", true], ["type", "text"], ["defaultValue", model_1.Term], ["onChange", (e) => {
            const x = e.target.value;
            dispatch(new Message(0, [x]));
            window.clearTimeout(patternInput_1[0]);
            patternInput_1[1](window.setTimeout((_arg) => {
                trySearch(x, dispatch);
            }, 500));
        }], ["variant", "outlined"], ["label", "Søk i aksjer og brukere"], ["style", {
            minWidth: 250 + "px",
            width: 100 + "%",
        }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => (((model_1.Processing ? true : (model_1.Results != null)) ? true : (model_1.ErrorMsg != null)) ? singleton_1(drawResults(model_1, dispatch)) : empty_1())));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

