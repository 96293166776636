import { Record, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, record_type, option_type, string_type, union_type, int32_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { PostProps_$reflection } from "../Pages.js";
import { Post_$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry2 } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useReact_useCallback_1CA17B65 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { empty as empty_1, singleton, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { LoadingIndicator_postLoadingIndicator } from "../ViewHelpers.js";
import { post as post_1 } from "./Post.js";
import { Dimensions_DefaultPadding } from "../Design.js";

class PostSource extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Asset", "Review", "InsiderTrade", "Short", "Invalid"];
    }
}

function PostSource_$reflection() {
    return union_type("Investfora.PostView.PostSource", [], PostSource, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], []]);
}

function PostSource_FromProps_6B69E059(x) {
    if (x != null) {
        const y = x;
        const matchValue = y.Src.toLocaleLowerCase();
        switch (matchValue) {
            case "ap":
                return new PostSource(0, [y.SrcId]);
            case "rv":
                return new PostSource(1, [y.SrcId]);
            case "it":
                return new PostSource(2, [y.SrcId]);
            case "sh":
                return new PostSource(3, [y.SrcId]);
            default:
                return new PostSource(4, []);
        }
    }
    else {
        return new PostSource(4, []);
    }
}

class LoadResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Loading", "Denied", "NotFound", "ErrorMsg", "Success"];
    }
}

function LoadResult_$reflection() {
    return union_type("Investfora.PostView.LoadResult", [], LoadResult, () => [[], [], [], [["Item", string_type]], []]);
}

class Model extends Record {
    constructor(Props, Source, Post, Load) {
        super();
        this.Props = Props;
        this.Source = Source;
        this.Post = Post;
        this.Load = Load;
    }
}

function Model_$reflection() {
    return record_type("Investfora.PostView.Model", [], Model, () => [["Props", option_type(PostProps_$reflection())], ["Source", PostSource_$reflection()], ["Post", option_type(Post_$reflection())], ["Load", LoadResult_$reflection()]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PostResponse", "PostRemoved", "RetryLoad"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.PostView.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Post_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", Post_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [], []]);
}

function init(props) {
    return new Model(props, PostSource_FromProps_6B69E059(props), undefined, new LoadResult(0, []));
}

function update(model, msg) {
    switch (msg.tag) {
        case 1:
            return new Model(undefined, model.Source, undefined, model.Load);
        case 2:
            return new Model(model.Props, model.Source, model.Post, new LoadResult(0, []));
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                return new Model(model.Props, model.Source, model.Post, (err.tag === 2) ? (((err.fields[0].status) === 401) ? (new LoadResult(1, [])) : (((err.fields[0].status) === 404) ? (new LoadResult(2, [])) : (new LoadResult(3, ["Kunne ikke laste innlegg. Vennligst prøv på nytt senere."])))) : ((err.tag === 3) ? (new LoadResult(3, ["En nettverksfeil oppsto, kunne ikke laste innlegg. Kontroller nettverkstilkoblingen og prøv på nytt."])) : (new LoadResult(3, ["Kunne ikke laste innlegg. Vennligst prøv på nytt senere."]))));
            }
            else {
                return new Model(model.Props, model.Source, res.fields[0], model.Load);
            }
        }
    }
}

function loadPost(src, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = (src.tag === 0) ? (`/api/asset/post/${src.fields[0]}`) : ((src.tag === 1) ? (`/api/review/${src.fields[0]}`) : ((src.tag === 2) ? (`/api/asset/insider-trade/${src.fields[0]}`) : ((src.tag === 3) ? (`/api/asset/short/event/${src.fields[0]}`) : "")));
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(Post_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(Post_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function errMsg(title, txt) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        textAlign: "center",
        marginTop: 20 + "vh",
    }], ["className", "centered-flex"], (elems = [createElement("span", {
        className: "title is-3",
        children: title,
    }), createElement("span", {
        style: {
            maxWidth: 400 + "px",
        },
        children: txt,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function drawPost(model, dispatch) {
    const onRemove = useReact_useCallback_1CA17B65((_arg) => {
        dispatch(new Message(1, []));
    });
    const children = toList(delay(() => {
        let elems_1;
        const matchValue = model.Post;
        if (matchValue == null) {
            if (model.Source.tag === 4) {
                return singleton(errMsg("Ugyldig lenke.", "Det ser ut til at lenken du prøvde åpne er ugyldig. Om du prøvde åpne denne siden gjennom å lime inn en lenke sjekk at du fikk med deg hele adressen."));
            }
            else {
                const matchValue_2 = model.Load;
                switch (matchValue_2.tag) {
                    case 0:
                        return singleton(LoadingIndicator_postLoadingIndicator());
                    case 1:
                        return singleton(errMsg("Ikke tilgang", "Dette innlegget kan kun sees av medlemmer av gruppen innlegget ble publisert i."));
                    case 2:
                        return singleton(errMsg("Fant ikke innlegg", "Innlegget du leter etter ble ikke funnet. Det kan være fjernet eller nettadressen er feil."));
                    case 3:
                        return singleton(createElement("div", createObj(ofArray([["className", "centered-flex"], (elems_1 = [createElement("button", {
                            className: "pill-button",
                            onClick: (_arg_1) => {
                                const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                    dispatch(new Message(2, []));
                                    return (new Promise(resolve => setTimeout(resolve, 500))).then(() => {
                                        loadPost(model.Source, dispatch);
                                        return Promise.resolve();
                                    });
                                }));
                                pr.then();
                            },
                            children: Interop_reactApi.Children.toArray(["Prøv på nytt"]),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
                    default: {
                        return empty_1();
                    }
                }
            }
        }
        else {
            return singleton(post_1(matchValue, onRemove));
        }
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function PostView(postViewInputProps) {
    let elems;
    const post = postViewInputProps.post;
    const initialModel = init(post);
    const patternInput = useReact_useReducer_2B9E6EA0(update, initialModel);
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        if (initialModel.Source.tag === 4) {
        }
        else {
            loadPost(initialModel.Source, dispatch);
        }
    });
    return createElement("div", createObj(ofArray([["className", "asset-column"], ["style", {
        padding: Dimensions_DefaultPadding,
    }], (elems = [drawPost(patternInput[0], dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

