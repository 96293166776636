const pushAd = () => {
    try {
        const adsbygoogle = window.adsbygoogle
        console.log({ adsbygoogle })
        adsbygoogle.push({})
    } catch (e) {
        console.error(e)
    }
}
     
function handleAds () {
    let interval = setInterval(() => {
        // Check if Adsense script is loaded every 200ms
        if (window.adsbygoogle) {
            pushAd()
            // clear the interval once the ad is pushed so that function isn't called indefinitely
            clearInterval(interval)
        }
    }, 200)   
}

export {handleAds};