import { toString, Union } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, union_type, int32_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { ReportReason, ReportReason__ToInt, ReportReason_AsArray } from "../Shared/Shared.js";
import { createElement } from "react";
import React from "react";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { GenericResponse_$reflection, ReportForm_$reflection, ReportForm } from "../Shared/ApiDataTypes.js";
import { Dialog_dialogActions, Alert_errorAlert, Dialog_dialogContent, Dialog_dialogTitle, Dialog_Dialog } from "../ViewHelpers.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { uncurry2, createObj } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { cons, empty, singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { map } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { map as map_1, defaultArg, some, unwrap } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString as toString_1 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";

export class ReportObject extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AssetReview", "AssetReviewComment", "AssetPost", "AssetPostComment", "User"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "analysekommentar" : ((this$.tag === 2) ? "innlegg" : ((this$.tag === 3) ? "kommentar" : ((this$.tag === 4) ? "bruker" : "analyse")));
    }
}

export function ReportObject_$reflection() {
    return union_type("Investfora.ReportForm.ReportObject", [], ReportObject, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export function ReportObject__ReportReasons(this$) {
    return ReportReason_AsArray();
}

export function ReportObject__ReportUrl(this$) {
    switch (this$.tag) {
        case 1:
            return `/api/review/reply/report/${this$.fields[0]}`;
        case 2:
            return `/api/asset/post/report/${this$.fields[0]}`;
        case 3:
            return `/api/asset/post/reply/report/${this$.fields[0]}`;
        case 4:
            return `/api/user/report/${this$.fields[0]}`;
        default:
            return `/api/review/report/${this$.fields[0]}`;
    }
}

export function Report(reportInputProps) {
    const onClose = reportInputProps.onClose;
    const target = reportInputProps.target;
    const patternInput = useFeliz_React__React_useState_Static_1505({
        Processing: false,
        Reported: false,
    });
    const updateState = patternInput[1];
    const state = patternInput[0];
    let patternInput_2;
    const patternInput_1 = (target.tag === 1) ? ["review-comment", target.fields[0]] : ((target.tag === 2) ? ["asset-post", target.fields[0]] : ((target.tag === 3) ? ["asset-post-comment", target.fields[0]] : ((target.tag === 4) ? ["user", target.fields[0]] : ["review", target.fields[0]])));
    patternInput_2 = useFeliz_React__React_useState_Static_1505(new ReportForm(patternInput_1[0], patternInput_1[1], ReportReason__ToInt(new ReportReason(0, [])), ""));
    const reportForm = patternInput_2[0];
    return createElement(Dialog_Dialog, {
        onClose: onClose,
        children: ofArray([Dialog_dialogTitle(`Rapporter ${toString(target)}`), Dialog_dialogContent(toList(delay(() => {
            let elems, elements, elems_1;
            return append(state.Reported ? singleton(createElement("div", createObj(ofArray([["style", {
                textAlign: "center",
                width: 100 + "%",
            }], (elems = [createElement("span", {
                children: ["Rapporteringen er mottatt"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : append(singleton(MuiHelpers_createElement(FormControl, [["fullWidth", true], ["style", {
                marginBottom: 10,
            }], (elements = [MuiHelpers_createElement(InputLabel, [["id", "reason-select-label-id"], ["children", "Begrunnelse"]]), MuiHelpers_createElement(Select, [["variant", "standard"], ["labelId", "reason-select-label-id"], ["label", "Begrunnelse"], ["fullWidth", true], ["onChange", (e_1, _arg_3) => {
                patternInput_2[1](new ReportForm(reportForm.Target, reportForm.TargetId, e_1.target.value, reportForm.Comment));
            }], ["value", reportForm.Reason], (elems_1 = map((rr) => MuiHelpers_createElement(MenuItem, [["value", ReportReason__ToInt(rr)], ["children", toString(rr)]]), ReportObject__ReportReasons(target)), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => singleton(MuiHelpers_createElement(TextField, [["fullWidth", true], ["multiline", true], ["label", "Kommentar (valgfritt)"], ["variant", "outlined"], ["size", "small"], ["rows", 5]])))), delay(() => singleton(Alert_errorAlert(state.ErrorMsg, () => {
                updateState({
                    Processing: state.Processing,
                    Reported: state.Reported,
                });
            }))));
        }))), Dialog_dialogActions(toList(delay(() => {
            let elems_3;
            return append(singleton(createElement("button", createObj(ofArray([["className", "pill-button-small"], ["onClick", (_arg_5) => {
                onClose();
            }], (elems_3 = [createElement("span", {
                style: {
                    marginRight: 5,
                },
                className: "fas fa-times",
            }), "Avbryt"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                let elems_4;
                return !state.Reported ? singleton(createElement("button", createObj(ofArray([["className", "pill-button-small"], ["onClick", (_arg_6) => {
                    if (!state.Processing) {
                        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let body, decoder, data_1, caseStrategy_3, extra_3;
                            updateState({
                                ErrorMsg: unwrap(state.ErrorMsg),
                                Processing: true,
                                Reported: state.Reported,
                            });
                            const requestPath = ReportObject__ReportUrl(target);
                            return ((body = Auto_generateBoxedEncoder_437914C6(ReportForm_$reflection(), undefined, undefined, undefined)(reportForm), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                                let properties_2;
                                try {
                                    const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                                        let response_1, decoder_1_1;
                                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                            let matchValue;
                                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                    }))));
                                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                                }
                                catch (exn) {
                                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                                }
                            })())))))).then((_arg_2) => {
                                const res = _arg_2;
                                if (res.tag === 0) {
                                    const x_1 = res.fields[0];
                                    if (x_1.Result === "success") {
                                        updateState({
                                            ErrorMsg: unwrap(state.ErrorMsg),
                                            Processing: false,
                                            Reported: true,
                                        });
                                        return Promise.resolve();
                                    }
                                    else {
                                        updateState({
                                            ErrorMsg: x_1.Message,
                                            Processing: false,
                                            Reported: state.Reported,
                                        });
                                        return Promise.resolve();
                                    }
                                }
                                else {
                                    updateState({
                                        ErrorMsg: "Kunne ikke registrere rapportering. Vennligst prøv igjen senere.",
                                        Processing: state.Processing,
                                        Reported: state.Reported,
                                    });
                                    return Promise.resolve();
                                }
                            });
                        }));
                        pr_1.then();
                    }
                }], (elems_4 = [createElement("span", {
                    style: {
                        marginRight: 5,
                    },
                    className: "fas fa-flag",
                }), "Rapporter"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))) : empty_1();
            }));
        })))]),
    });
}

