import { Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { Post_$reflection } from "./Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, int32_type, bool_type, string_type, option_type, array_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { FeedPreferences, storeFeedPreference, tryGetFeedPreferences } from "./LocalStorage.js";
import { mapIndexed, map, sort, tryHead, sortByDescending, append } from "./fable_modules/fable-library-js.4.17.0/Array.js";
import { parseAsUtc } from "./Utils.js";
import { Array_distinctBy } from "./fable_modules/fable-library-js.4.17.0/Seq2.js";
import { createObj, uncurry2, comparePrimitives, stringHash } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { compare } from "./fable_modules/fable-library-js.4.17.0/Date.js";
import { empty as empty_1, singleton, append as append_1, delay, toList, fold } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { unwrap, map as map_1, defaultArg, toArray } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.17.0/String.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { cons, ofArray, empty } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { LoadingIndicator_postLoadingIndicator, Alert_snackError } from "./ViewHelpers.js";
import { DefaultBorder } from "./Design.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { post } from "./Content/Post.js";
import { FeedEntry } from "./Content/Ads.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";

class Model extends Record {
    constructor(Posts, ErrorMsg, IncludeShort, LastReviewId, LastAssetPostId, LastShortEventDate, ShortSkips, LastInsiderTradeId, More) {
        super();
        this.Posts = Posts;
        this.ErrorMsg = ErrorMsg;
        this.IncludeShort = IncludeShort;
        this.LastReviewId = LastReviewId;
        this.LastAssetPostId = LastAssetPostId;
        this.LastShortEventDate = LastShortEventDate;
        this.ShortSkips = ShortSkips;
        this.LastInsiderTradeId = LastInsiderTradeId;
        this.More = More;
    }
}

function Model_$reflection() {
    return record_type("Investfora.Feed.Model", [], Model, () => [["Posts", option_type(array_type(Post_$reflection()))], ["ErrorMsg", option_type(string_type)], ["IncludeShort", bool_type], ["LastReviewId", option_type(int32_type)], ["LastAssetPostId", option_type(int32_type)], ["LastShortEventDate", option_type(string_type)], ["ShortSkips", option_type(int32_type)], ["LastInsiderTradeId", option_type(int32_type)], ["More", bool_type]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PostsResponse", "ToggleIncludeShort", "AppendPost", "RemovePost", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.Feed.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Post_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Post_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", bool_type]], [["Item", Post_$reflection()]], [["Item", int32_type]], []]);
}

function init() {
    let matchValue;
    return new Model(undefined, undefined, (matchValue = tryGetFeedPreferences(), (matchValue.tag === 0) ? matchValue.fields[0].IncludeShort : true), undefined, undefined, undefined, undefined, undefined, true);
}

function update(model, msg) {
    let matchValue_5, matchValue_6, array_21, matchValue, lse, array_16, array_15, lowest_3, matchValue_4, l_3;
    switch (msg.tag) {
        case 1: {
            const x_7 = msg.fields[0];
            storeFeedPreference(new FeedPreferences(x_7));
            return new Model(x_7 ? [] : model.Posts, model.ErrorMsg, x_7, x_7 ? undefined : model.LastReviewId, x_7 ? undefined : model.LastAssetPostId, x_7 ? undefined : model.LastShortEventDate, x_7 ? undefined : model.ShortSkips, model.LastInsiderTradeId, true);
        }
        case 2: {
            const x_8 = msg.fields[0];
            return new Model((matchValue_5 = model.Posts, (matchValue_5 == null) ? [x_8] : append([x_8], matchValue_5)), model.ErrorMsg, model.IncludeShort, model.LastReviewId, model.LastAssetPostId, model.LastShortEventDate, model.ShortSkips, model.LastInsiderTradeId, model.More);
        }
        case 3:
            return new Model((matchValue_6 = model.Posts, (matchValue_6 != null) ? ((array_21 = matchValue_6, array_21.filter((a) => (a.Id !== msg.fields[0])))) : undefined), model.ErrorMsg, model.IncludeShort, model.LastReviewId, model.LastAssetPostId, model.LastShortEventDate, model.ShortSkips, model.LastInsiderTradeId, model.More);
        case 4:
            return new Model(model.Posts, undefined, model.IncludeShort, model.LastReviewId, model.LastAssetPostId, model.LastShortEventDate, model.ShortSkips, model.LastInsiderTradeId, model.More);
        default: {
            const res = msg.fields[0];
            if (res.tag === 0) {
                const x = res.fields[0];
                const p = sortByDescending((z_1) => parseAsUtc(z_1.Published), Array_distinctBy((z) => (`${z.Type}-${z.Id}`), (matchValue = model.Posts, (matchValue != null) ? append(matchValue, x) : x), {
                    Equals: (x_1, y_3) => (x_1 === y_3),
                    GetHashCode: stringHash,
                }), {
                    Compare: compare,
                });
                let lastReviewId;
                const lowest = tryHead(sort(map((y_6) => y_6.Id, p.filter((y_5) => (y_5.Type === "review")), Int32Array), {
                    Compare: comparePrimitives,
                }));
                const matchValue_1 = model.LastReviewId;
                if (matchValue_1 != null) {
                    const l = matchValue_1 | 0;
                    lastReviewId = fold((_arg, z_2) => {
                        if (z_2 < l) {
                            return z_2 | 0;
                        }
                        else {
                            return l | 0;
                        }
                    }, l, toArray(lowest));
                }
                else {
                    lastReviewId = lowest;
                }
                let lastAssetPostId;
                const lowest_1 = tryHead(sort(map((y_9) => y_9.Id, p.filter((y_8) => (y_8.Type === "asset-post")), Int32Array), {
                    Compare: comparePrimitives,
                }));
                const matchValue_2 = model.LastAssetPostId;
                if (matchValue_2 != null) {
                    const l_1 = matchValue_2 | 0;
                    lastAssetPostId = fold((_arg_1, z_3) => {
                        if (z_3 < l_1) {
                            return z_3 | 0;
                        }
                        else {
                            return l_1 | 0;
                        }
                    }, l_1, toArray(lowest_1));
                }
                else {
                    lastAssetPostId = lowest_1;
                }
                let patternInput;
                const lowest_2 = tryHead(sort(map((y_12) => y_12.Published, p.filter((y_11) => (y_11.Type === "short-event"))), {
                    Compare: comparePrimitives,
                }));
                const matchValue_3 = model.LastShortEventDate;
                if (matchValue_3 != null) {
                    const l_2 = matchValue_3;
                    const newLowest = fold((_arg_2, z_4) => {
                        if (z_4 < l_2) {
                            return z_4;
                        }
                        else {
                            return l_2;
                        }
                    }, l_2, toArray(lowest_2));
                    patternInput = [newLowest, newLowest !== l_2];
                }
                else {
                    patternInput = [lowest_2, false];
                }
                const lastShortEventDate = patternInput[0];
                return new Model(p, model.ErrorMsg, model.IncludeShort, lastReviewId, lastAssetPostId, lastShortEventDate, patternInput[1] ? undefined : ((lastShortEventDate != null) ? ((lse = lastShortEventDate, (array_16 = ((array_15 = p.filter((e) => (e.Type === "short-event")), array_15.filter((e_1) => (e_1.Published === lse)))), array_16.length))) : undefined), (lowest_3 = tryHead(sort(map((y_15) => y_15.Id, p.filter((y_14) => (y_14.Type === "insider-trade")), Int32Array), {
                    Compare: comparePrimitives,
                })), (matchValue_4 = model.LastInsiderTradeId, (matchValue_4 != null) ? ((l_3 = (matchValue_4 | 0), fold((_arg_3, z_5) => {
                    if (z_5 < l_3) {
                        return z_5 | 0;
                    }
                    else {
                        return l_3 | 0;
                    }
                }, l_3, toArray(lowest_3)))) : lowest_3)), x.length > 0);
            }
            else {
                const err = res.fields[0];
                return new Model(model.Posts, (err.tag === 2) ? (((err.fields[0].status) === 401) ? "Kunne ikke laste feed, ikke innlogget. Vennligst logg inn på nytt." : "Kunne ikke laste feed. Vennligst prøv igjen senere.") : "Kunne ikke laste feed. Vennligst prøv igjen senere.", model.IncludeShort, model.LastReviewId, model.LastAssetPostId, model.LastShortEventDate, model.ShortSkips, model.LastInsiderTradeId, model.More);
            }
        }
    }
}

function fetchFeed(revId, apId, seDt, seSkip, itId, includeShort, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        let requestPath;
        const r = fold((_arg, x) => (`&rid=${x}`), "", toArray(revId));
        const a = fold((_arg_1, x_1) => (`&pid=${x_1}`), "", toArray(apId));
        const seDt$0027 = fold((_arg_2, x_2) => (`&sedt=${x_2}`), "", toArray(seDt));
        const se = fold((_arg_3, x_3) => (`&se_skip=${x_3}`), "", toArray(seSkip));
        const insider = fold((_arg_4, x_4) => (`&itid=${x_4}`), "", toArray(itId));
        const excludeShort = includeShort ? "" : "&include_short=false";
        const q = ((((((revId != null) ? true : (apId != null)) ? true : (seDt != null)) ? true : (seSkip != null)) ? true : (itId != null)) ? true : !includeShort) ? "?" : "";
        requestPath = toText(printf("/api/feed%s%s%s%s%s%s%s"))(q)(r)(a)(seDt$0027)(se)(insider)(excludeShort);
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Post_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg_5) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg_5, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Post_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_6) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_6), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_7) => (new FSharpResult$2(1, [new FetchError(3, [arg_7])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_7) => {
            dispatch(new Message(0, [_arg_7]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function view(model, dispatch) {
    let elems_3;
    return createElement("div", createObj(ofArray([["className", "feed"], ["style", {
        height: 100 + "%",
        marginBottom: 5 + "vh",
    }], (elems_3 = toList(delay(() => append_1(singleton(Alert_snackError(model.ErrorMsg, () => {
        dispatch(new Message(4, []));
    })), delay(() => {
        let elems;
        return append_1(singleton(createElement("div", createObj(ofArray([["style", createObj(ofArray([DefaultBorder, ["backgroundColor", "#FFFFFF"]]))], (elems = [MuiHelpers_createElement(FormControlLabel, [["label", "Inkluder short-oppdateringer"], ["checked", model.IncludeShort], ["style", {
            margin: ((0 + "px ") + 5) + "px",
        }], ["control", MuiHelpers_createElement(Checkbox, [["color", "primary"]])], ["onChange", (e) => {
            const b = e.target.checked;
            dispatch(new Message(1, [b]));
            if (b) {
                fetchFeed(undefined, undefined, undefined, undefined, undefined, true, dispatch);
            }
        }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1, xs_5;
            const matchValue = model.Posts;
            if (matchValue != null) {
                const x = matchValue;
                return (x.length === 0) ? singleton(createElement("div", createObj(ofArray([["style", {
                    height: 100 + "%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                }], (elems_1 = [createElement("span", {
                    style: {
                        fontSize: 2 + "rem",
                        fontWeight: "bold",
                        margin: ((20 + "px ") + 0) + "px",
                    },
                    children: "Her var det tomt",
                }), createElement("br", {}), createElement("p", {
                    children: ["Analyser og innlegg fra dine favorittaksjer dukker opp her."],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : append_1(singleton((xs_5 = mapIndexed((i, y) => {
                    const xs_4 = toList(delay(() => append_1(singleton(post(y, (arg) => {
                        dispatch(new Message(3, [arg]));
                    })), delay(() => ((((i + 1) % 3) === 0) ? singleton(createElement(FeedEntry, null)) : empty_1())))));
                    return react.createElement(react.Fragment, {}, ...xs_4);
                }, x), react.createElement(react.Fragment, {}, ...xs_5))), delay(() => {
                    let elems_2;
                    return model.More ? singleton(createElement("div", createObj(ofArray([["style", {
                        textAlign: "center",
                        margin: ((10 + "px ") + 0) + "px",
                    }], (elems_2 = [createElement("button", {
                        className: "pill-button-small",
                        onClick: (_arg_1) => {
                            fetchFeed(model.LastReviewId, model.LastAssetPostId, model.LastShortEventDate, model.ShortSkips, model.LastInsiderTradeId, model.IncludeShort, dispatch);
                        },
                        children: "Last mer",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))) : empty_1();
                }));
            }
            else {
                return append_1(singleton(LoadingIndicator_postLoadingIndicator()), delay(() => singleton(LoadingIndicator_postLoadingIndicator())));
            }
        }));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function Feed() {
    const initialModel = init();
    const patternInput = useReact_useReducer_2B9E6EA0(update, initialModel);
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchFeed(initialModel.LastReviewId, initialModel.LastAssetPostId, initialModel.LastShortEventDate, initialModel.ShortSkips, model_1.LastInsiderTradeId, initialModel.IncludeShort, dispatch);
    });
    return view(model_1, dispatch);
}

